import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col } from 'antd';
import classNames from 'classnames';

import routes from 'routing/routes';
import buildPath from 'utils/build_path';
import formatCurrencyPrice from 'utils/format_currency_price';
import calculatePercentage from 'utils/calculate_percentage';
import useTrackingCookieConsent from 'hooks/useTrackingCookieConsent';

import { IGiftCardStoreListProps, IPublishedGiftCard } from './gift_card_store_list.types';
import styles from './gift_card_store_list.module.scss';

const GiftCardStoreList: React.FC<IGiftCardStoreListProps> = ({ giftCardsList, property }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const getItemSize = () => {
    if (window.innerWidth <= 750) return 24;
    if (750 <= window.innerWidth && window.innerWidth < 1100) return 12;
    if (1500 <= window.innerWidth && window.innerWidth <= 1700) return 6;
    if (1000 <= window.innerWidth && window.innerWidth <= 1950) return 8;
    if (1850 < window.innerWidth && window.innerWidth < 2000) return 6;

    return 6;
  };

  const { selectedProperty } = useParams<{ selectedProperty: string }>();
  const [itemSize, setItemSize] = useState<number>(getItemSize());
  const isTrackingAllowed: boolean = useTrackingCookieConsent();

  const handleRedirect = (giftCard: IPublishedGiftCard) => {
    isTrackingAllowed &&
      window?.gtag('event', 'view_item', {
        currency: property?.currency,
        value: giftCard.netPrice,
        items: [
          {
            item_id: giftCard.id,
            item_name: giftCard.title,
            item_category: 'Gift Card',
            price: giftCard.netPrice,
            discount: giftCard.price - giftCard.netPrice,
          },
        ],
      });
    const redirectPath = buildPath(history.location.search, routes.giftCardStoreDetailPage, {
      selectedProperty,
      giftCardId: giftCard?.id,
    });

    history.push(redirectPath);
  };

  window.addEventListener('resize', () => {
    const size = getItemSize();
    setItemSize(size);
  });

  const offsetSize = giftCardsList?.length < 24 / itemSize ? (24 - giftCardsList?.length * itemSize) / 2 : 0;

  const getOffset = (index: number) => {
    if (giftCardsList?.length < 24 / itemSize && (index === 0 || (index + 1) % (24 / itemSize) === 0))
      return offsetSize;
    return 0;
  };

  const renderGiftCardsContent = useMemo(() => {
    if (!giftCardsList?.length || !property) return;
    return giftCardsList?.map((giftCard: IPublishedGiftCard, index: number) => (
      <Col
        key={`giftCard-${giftCard.id}`}
        md={itemSize}
        offset={getOffset(index)}
        data-testid="GiftCard"
        className={styles.giftCard}
      >
        <Row className={classNames(styles.giftCardContainer, 'roundedWrapper')} justify="space-between">
          <Col span={24}>
            <Row>
              <Col span={24} className={styles.imageContainer}>
                <img
                  src={
                    giftCard.photos?.[0]?.publicUrl ||
                    'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/best-gift-cards-2021-1637270343.jpg?crop=1.00xw:0.652xh;0,0.160xh&resize=980:*'
                  }
                  onClick={() => handleRedirect(giftCard)}
                  className="roundedWrapper"
                  alt="Gift Card"
                />
              </Col>
              <Col span={24} className={classNames('highlighted', styles.giftCardPrice)}>
                <Row justify="space-between" align="middle" gutter={[10, 10]} className={styles.giftCardPriceDetails}>
                  <Col
                    span={
                      giftCard.netPrice !== giftCard.price && calculatePercentage(giftCard.netPrice, giftCard.price)
                        ? 12
                        : 24
                    }
                  >
                    {formatCurrencyPrice(giftCard.netPrice, property?.currency, property?.country)}
                  </Col>
                  {giftCard.netPrice !== giftCard.price && calculatePercentage(giftCard.netPrice, giftCard.price) && (
                    <>
                      <Col span={12}>
                        <div className={'discountPercentage'}>
                          {calculatePercentage(giftCard.netPrice, giftCard.price)}
                        </div>
                      </Col>
                      <Col span={12} className={'discountPrice'}>
                        {formatCurrencyPrice(giftCard.price, property?.currency, property?.country)}
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
              <Col
                span={24}
                className={classNames('highlighted', styles.giftCardName)}
                onClick={() => handleRedirect(giftCard)}
              >
                {giftCard.title}
              </Col>
              <Col span={24} className={styles.giftCardDescription}>
                {giftCard.description.replace(/(<([^>]+)>)/gi, '')}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row className={styles.buttonContainer}>
              <Col span={24}>
                <Button
                  className={classNames(styles.chooseButton, 'highlighted')}
                  onClick={() => handleRedirect(giftCard)}
                >
                  {t('gift_card.view_certificates')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    ));
  }, [giftCardsList, itemSize, property]);

  return (
    <div data-testid="GiftCardStoreList">
      <Row gutter={40}>{renderGiftCardsContent}</Row>
    </div>
  );
};

export default GiftCardStoreList;
