import React, { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Steps, Input, Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import formatCurrencyPrice from 'utils/format_currency_price';
import DATE_FORMATTER from 'utils/date_formatter';
import PURCHASE_TYPE from 'constants/gift_card_purchase';

import { IGiftCardPurchaseDetailsFormProps } from './gift_card_purchase_details_form.types';
import { IGiftCardPurchase } from 'components/gift_card_purchases/gift_card_purchases.types';
import styles from './gift_card_purchase_details_form.module.scss';

const issuedStatuses = ['pending', 'queued', 'issued'];
const paymentStatuses = ['initiated', 'paid'];

const GiftCardPurchaseDetailsForm: React.FC<IGiftCardPurchaseDetailsFormProps> = ({
  purchase,
  onUpdateRecipients,
  submitLoading,
}) => {
  const { t } = useTranslation();

  const {
    email,
    fullName,
    generatedId,
    referenceNumber,
    status,
    quantity,
    totalAmount,
    currency,
    country,
    issuedStatus,
    createdAt,
    emailSent,
    orderRecipients,
  } = purchase;
  const formatLabel = useCallback((label: string) => `${t(label)}:`, []);

  const areGiftCardsIssued = (purchase: IGiftCardPurchase) => {
    return purchase.issuedStatus == PURCHASE_TYPE.ISSUED;
  };

  const areGiftCardsPending = (purchase: IGiftCardPurchase) => {
    return purchase.issuedStatus == PURCHASE_TYPE.PENDING;
  };

  const productsLabel = (purchase: IGiftCardPurchase) => {
    return purchase.products.length > 1 ? t('gift_card.purchase.products') : t('gift_card.purchase.product');
  };

  const initialRecipients = useMemo(() => {
    const orderRecipientsSize = purchase?.orderRecipients?.length;
    if (areGiftCardsPending(purchase) && quantity !== orderRecipientsSize) {
      const missingRecipients = Array.from({ length: quantity - orderRecipientsSize }, () => ({ name: '', email: '' }));
      const newInputRecipients = [...orderRecipients, ...missingRecipients];

      return newInputRecipients;
    }

    return orderRecipients;
  }, [purchase]);

  const [inputRecipients, setInputRecipients] = useState<IGiftCardPurchase['orderRecipients']>(initialRecipients);

  const handlePaste = (index: number, startColumn: number) => async (event: React.ClipboardEvent) => {
    event.preventDefault();
    const text = event.clipboardData.getData('text');
    const rows = text.split(/\r\n|\n|\r/).filter(row => row.trim() !== '');
    const newRecipients = [...inputRecipients];

    const rowLength = rows.length;
    let editedRows = 0;
    for (let i = index; i < quantity; i++) {
      if (editedRows === rowLength) {
        break;
      }

      const splitRow = rows[editedRows].split('\t');
      const name = splitRow[0];
      const email = splitRow[1];

      // Only 1 value on clipboard
      if (i === index && splitRow.length === 1) {
        if (startColumn === 0) {
          newRecipients[i].name = splitRow[0];
        } else if (startColumn === 1) {
          newRecipients[i].email = splitRow[0];
        }
        // Skip first value if pasted in from the email field
      } else if (i === index && startColumn === 1) {
        newRecipients[i].email = email;
      } else {
        newRecipients[i].name = name;
        newRecipients[i].email = email;
      }
      editedRows++;
    }

    setInputRecipients(newRecipients);
  };

  const handleOnChange = (index: number, column: number) => async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const property = column === 0 ? 'name' : 'email';

    setInputRecipients(currentRecipients => {
      const updatedRecipients = [...currentRecipients];

      updatedRecipients[index] = { ...updatedRecipients[index], [property]: value };

      return updatedRecipients;
    });
  };

  const validateEmail = (email: string) => {
    if ((email?.length ?? 0) === 0) {
      return 'error';
    }

    const emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
    if (emailRegex.test(email)) {
      return '';
    }

    return 'error';
  };

  const handleOnClick = () => {
    const emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;

    const validFields = inputRecipients.every(
      recipient => emailRegex.test(recipient.email) === true && recipient.name !== '',
    );
    const usePurchaseRecipientInfo = inputRecipients.every(
      recipient => recipient.email === '' || recipient.name === '',
    );

    if (validFields || usePurchaseRecipientInfo) {
      // Orders without recipients use name and email of purchaser
      if (usePurchaseRecipientInfo) {
        setInputRecipients([]);
      }

      onUpdateRecipients(purchase, inputRecipients);
    }

    return;
  };

  const handleOnClickCopyRecipients = () => {
    const copiedRecipients = Array.from({ length: quantity }, () => inputRecipients[0]);
    setInputRecipients(copiedRecipients);

    return;
  };

  return (
    <div data-testid="GiftCardPurchaseDetailsForm" className={styles.root}>
      {fullName && (
        <Row role="name" className={styles.row}>
          <Col span={8}>{formatLabel('general.other.full_name')}</Col>
          <Col span={16}>{fullName}</Col>
        </Row>
      )}
      {email && (
        <Row role="email" className={styles.row}>
          <Col span={8}>{formatLabel('general.other.email')}</Col>
          <Col span={16}>{email}</Col>
        </Row>
      )}
      {createdAt && (
        <Row role="createdAt" className={styles.row}>
          <Col span={8}>{formatLabel('general.created_at')}</Col>
          <Col span={16}>{DATE_FORMATTER.toUi(createdAt)}</Col>
        </Row>
      )}
      {generatedId && (
        <Row role="generatedId" className={styles.row}>
          <Col span={8}>{formatLabel('gift_card.purchase.generated_id')}</Col>
          <Col span={16}>{generatedId}</Col>
        </Row>
      )}
      {referenceNumber && (
        <Row role="referenceNumber" className={styles.row}>
          <Col span={8}>{formatLabel('gift_card.purchase.reference_number')}</Col>
          <Col span={16}>{referenceNumber}</Col>
        </Row>
      )}

      <Row role="status" className={styles.row}>
        <Col span={8}>{formatLabel('gift_card.purchase_status')}</Col>
        <Col span={16}>
          <Steps
            size="small"
            current={paymentStatuses.indexOf(status)}
            items={[{ title: 'Initiated' }, { title: 'Paid' }]}
          />
        </Col>
      </Row>

      <Row role="issuedStatus" className={styles.row}>
        <Col span={8}>{formatLabel('gift_card.issued_status')}</Col>
        <Col span={16}>
          <Steps
            size="small"
            current={issuedStatuses.indexOf(issuedStatus)}
            items={[
              { title: t('gift_card.purchase.pending') },
              { title: t('gift_card.purchase.queued') },
              { title: t('gift_card.purchase.issued') },
            ]}
          />
        </Col>
      </Row>

      {quantity && (
        <Row role="quantity" className={styles.row}>
          <Col span={8}>{formatLabel('gift_card.purchase.quantity')}</Col>
          <Col span={16}>{quantity}</Col>
        </Row>
      )}
      {totalAmount && (
        <Row role="totalAmount" className={styles.row}>
          <Col span={8}>{formatLabel('general.total_amount')}</Col>
          <Col span={16}>{formatCurrencyPrice(totalAmount, currency, country)}</Col>
        </Row>
      )}
      <Row role="emailSent" className={styles.row}>
        <Col span={8}>{formatLabel('gift_card.purchase.email_sent')}</Col>
        <Col span={16}>{emailSent ? t('general.yes') : t('general.no')}</Col>
      </Row>

      {areGiftCardsIssued(purchase) && (
        <Row role="recipients" className={styles.row}>
          <Col span={8}>{t('gift_card.purchase.email_recipients')}</Col>
          <Col span={16}>
            {purchase.recipients.map(({ name, email }, index) => (
              <Row key={index}>
                <Col span={8}>{name}</Col>
                <Col span={8}>{email}</Col>
              </Row>
            ))}
          </Col>
        </Row>
      )}

      {areGiftCardsPending(purchase) && (
        <Row role="orderRecipients" className={styles.orderRecipients}>
          <Col span={8}>{t('gift_card.purchase.order_recipients')}</Col>
          <Col span={16}>
            {inputRecipients.map(({ name, email }, index) => (
              <Row key={index}>
                <Col span={12}>
                  <Input
                    value={name}
                    placeholder={t('gift_card.name')}
                    onPaste={handlePaste(index, 0)}
                    onChange={handleOnChange(index, 0)}
                    status={(name?.length ?? 0) === 0 ? 'error' : ''}
                    required
                  />
                </Col>
                <Col span={11}>
                  <Input
                    value={email}
                    type="email"
                    placeholder={t('gift_card.email')}
                    onPaste={handlePaste(index, 1)}
                    onChange={handleOnChange(index, 1)}
                    status={validateEmail(email)}
                    required
                  />
                </Col>
                <>
                  {index === 0 && (
                    <Col span={1}>
                      <Button
                        title={t('gift_card.purchase.copy_recipients')}
                        icon={<CopyOutlined />}
                        onClick={handleOnClickCopyRecipients}
                      />
                    </Col>
                  )}
                </>
              </Row>
            ))}
          </Col>
        </Row>
      )}

      {purchase.products.length > 0 && (
        <Row role="products" className={styles.row}>
          <Col span={8}>{productsLabel(purchase)}</Col>
          <Col span={16}>
            {purchase.products.map((product, index) => (
              <Row key={index}>{`${product[1]} x ${product[0]}`}</Row>
            ))}
          </Col>
        </Row>
      )}

      {areGiftCardsPending(purchase) && (
        <div className={styles.submit_section}>
          <Button
            className={styles.submit_button}
            loading={submitLoading}
            type="primary"
            htmlType="submit"
            size="large"
            onClick={handleOnClick}
          >
            {t('gift_card.update_type')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default GiftCardPurchaseDetailsForm;
