import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { decode } from 'js-base64';
import { notification } from 'antd';

import routes from 'routing/routes';
import buildPath from 'utils/build_path';
import useTrackingCookieConsent from 'hooks/useTrackingCookieConsent';

import Loading from 'components/loading';
import GiftCardStoreCheckout from 'components/gift_card_store_checkout/gift_card_store_checkout';
import {
  ICartItem,
  ICheckoutFormValues,
  IGiftCard,
} from 'components/gift_card_store_checkout/gift_card_store_checkout.types';

import { PropertyGiftCardsActionsContext, PropertyGiftCardsDataContext } from 'containers/data_context';

import styles from './gift_card_store_checkout_page.module.scss';

const GiftCardStoreCheckoutPage: React.FC = () => {
  const { i18n } = useTranslation();
  const history = useHistory();

  const { selectedProperty } = useParams<{ selectedProperty: string }>();

  const { getStoreGiftCardTypes, setGiftCardCheckoutData, clearDataFromStorage } = useContext(
    PropertyGiftCardsActionsContext,
  );

  const [cartItems, setCartItems] = useState<Array<ICartItem>>([]);
  const isTrackingAllowed: boolean = useTrackingCookieConsent();

  const {
    storeGiftCardTypes: { data, isLoading },
    propertyInfo: { data: propertyDetails },
  } = useContext(PropertyGiftCardsDataContext);

  useEffect(
    function initApp() {
      getStoreGiftCardTypes({ propertyId: selectedProperty });
    },
    [getStoreGiftCardTypes],
  );

  useEffect(() => {
    const cartitem = localStorage.getItem(`cart-items-${selectedProperty}`);

    if (data && cartitem) {
      const cartItems = JSON.parse(decode(cartitem)).items;

      const isItemAvailable = cartItems.every((item: ICartItem) =>
        data.find((giftCard: IGiftCard) => item.item.id == giftCard.id),
      );

      if (!isItemAvailable) {
        localStorage.removeItem(`cart-items-${selectedProperty}`);

        const redirectPath = buildPath('', routes.giftCardStorePage, { selectedProperty });
        history.push(redirectPath);

        return notification['error']({
          message: 'An error has occured.',
          description: 'Sorry, the product is sold out.',
        });
      } else {
        setCartItems(JSON.parse(decode(cartitem)).items);
      }
    } else {
      if (!cartitem) {
        const redirectPath = buildPath('', routes.giftCardStorePage, { selectedProperty });
        history.push(redirectPath);

        return notification['error']({
          message: 'An error has occured.',
          description: 'The basket is empty',
        });
      }
    }
  }, [data]);

  const onSaveGiftCardInfo = async (giftCardData: ICheckoutFormValues) => {
    const giftCardParams = { ...giftCardData, locale: i18n.language };
    try {
      const response = await setGiftCardCheckoutData({ giftCardParams, propertyId: selectedProperty });
      isTrackingAllowed && window?.gtag('event', 'add_payment_info');
      if (response?.redirectUrl) {
        clearDataFromStorage();
        localStorage.removeItem(`cart-items-${selectedProperty}`);
        window.location = response?.redirectUrl;
      } else {
        const { formActionUrl, ...rest } = response;

        const params = new URLSearchParams(rest);
        window.location.href = formActionUrl + '?' + params;
        return;
      }
    } catch (e) {
      return notification['error']({
        message: 'Something went wrong. Please try again.',
      });
    }
  };

  return (
    <div className={styles.root} data-testid="GiftCardStoreCheckoutPage">
      {!data || isLoading ? (
        <Loading />
      ) : (
        <GiftCardStoreCheckout
          cartItems={cartItems}
          onSaveGiftCardInfo={onSaveGiftCardInfo}
          storeSettings={propertyDetails?.storeSetting}
          property={propertyDetails}
        />
      )}
    </div>
  );
};

export default GiftCardStoreCheckoutPage;
