import React, { useEffect, useState, useContext } from 'react';
import { decode } from 'js-base64';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import routes from 'routing/routes';
import buildPath from 'utils/build_path';

import GiftCardStoreCart from 'components/gift_card_store_cart';
import { ICartItem } from 'components/gift_card_store_cart/gift_card_store_cart.types';

import { PropertyGiftCardsActionsContext, PropertyGiftCardsDataContext } from 'containers/data_context';

import styles from './gift_card_store_cart_page.module.scss';
import PageTitle from 'components/page_title/page_title';

const GiftCardStoreCartPage: React.FC = () => {
  const { t } = useTranslation();

  const { selectedProperty } = useParams<{ selectedProperty: string }>();

  const { getGiftCardTypeSize } = useContext(PropertyGiftCardsActionsContext);

  const {
    propertyInfo: { data: property },
  } = useContext(PropertyGiftCardsDataContext);

  const [cartItems, setCartItems] = useState<ICartItem[]>([]);
  const cartItem = localStorage.getItem(`cart-items-${selectedProperty}`);

  useEffect(() => {
    if (cartItem) {
      setCartItems(JSON.parse(decode(cartItem)).items);
    }
  }, [cartItem]);

  const handleBuildPath = (path: string) => buildPath('', path, { selectedProperty });

  return (
    <div className={styles.root} data-testid="GiftCardStoreCartPage">
      <PageTitle
        steps={[
          { title: t('general.home'), link: handleBuildPath(routes.giftCardStorePage) },
          { title: t('gift_card.basket') },
        ]}
      />
      <GiftCardStoreCart cartItems={cartItems} onGetGiftCardTypeSize={getGiftCardTypeSize} property={property} />
    </div>
  );
};

export default GiftCardStoreCartPage;
