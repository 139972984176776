// Generated with util/create-component.js
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Dropdown, MenuProps, Modal, Drawer, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import styles from './gift_card_purchases.module.scss';
import DATE_FORMATTER from 'utils/date_formatter';
import notification from 'utils/notification/notification';
import formatCurrencyPrice from 'utils/format_currency_price';
import { IGiftCardPurchaseProps, IGiftCardPurchase } from './gift_card_purchases.types';
import { PropertyGiftCardsActionsContext, PropertySettingsDataContext } from 'containers/data_context';
import PURCHASE_TYPE from 'constants/gift_card_purchase';
import DRAWER_SIZE from 'constants/drawer_size';
import dayjs from 'dayjs';
import GiftCardPurchaseDetailsForm from 'components/gift_card_purchase_details_form/gift_card_purchase_details_form';

const GiftCardPurchases: React.FC<IGiftCardPurchaseProps> = ({
  giftCardsPurchases,
  onDownloadPdfs,
  onChange,
  pagination,
  isLoading,
  property,
}) => {
  const { t } = useTranslation();

  const { putGiftCardPurchaseMarkPaid, putGiftCardPurchaseMarkIssued, putGiftCardPurchaseUpdateRecipients } =
    useContext(PropertyGiftCardsActionsContext);
  const { selectedProperty } = useContext(PropertySettingsDataContext);

  const [purchaseList, setPurchaseList] = useState<IGiftCardPurchase[]>(giftCardsPurchases);
  const [giftCardPurchase, setGiftCardPurchase] = useState<IGiftCardPurchase>();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [showConfirmationIssuedModal, setShowConfirmationIssuedModal] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showDetailsDrawer, setShowDetailsDrawer] = useState<boolean>(false);
  const [submitUpdateRecipientsLoading, setSubmitUpdateRecipientsLoading] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setPurchaseList(giftCardsPurchases);
  }, [giftCardsPurchases]);

  const isPurchasePaid = (purchase: IGiftCardPurchase) => {
    return purchase.status === PURCHASE_TYPE.PAID;
  };

  const arePurchaseGiftCardsIssued = (purchase: IGiftCardPurchase) => {
    if (purchase.issuedStatus === PURCHASE_TYPE.PENDING) return false;
    return true;
  };

  const handleMarkAsPaidModal = (purchase: IGiftCardPurchase) => {
    setGiftCardPurchase(purchase);
    setShowConfirmationModal(true);
  };

  const openDetailsDrawer = (purchase: IGiftCardPurchase) => {
    setGiftCardPurchase(purchase);
    setShowDetailsDrawer(true);
  };

  const closeDetailsDrawer = () => {
    setShowDetailsDrawer(false);
  };

  const handleMarkAsIssuedModal = (purchase: IGiftCardPurchase) => {
    setGiftCardPurchase(purchase);
    setShowConfirmationIssuedModal(true);
  };

  const handleOkMarkAsPaid = () => {
    giftCardPurchase && markGiftCardPurchasePaid(giftCardPurchase);
    setShowConfirmationModal(false);
  };

  const handleOkMarkAsIssued = () => {
    giftCardPurchase && markGiftCardPurchaseIssued(giftCardPurchase);
    setShowConfirmationIssuedModal(false);
  };

  const handleCancelMark = () => {
    setShowConfirmationModal(false);
    setShowConfirmationIssuedModal(false);
  };

  const markGiftCardPurchasePaid = async (purchase: IGiftCardPurchase) => {
    const purchaseId = purchase.id;
    const data = await putGiftCardPurchaseMarkPaid({ propertyId: selectedProperty, purchaseId: purchaseId });
    const index = purchaseList.findIndex(purchase => purchase.id === purchaseId);

    purchaseList[index].status = data.status;
    setPurchaseList(purchaseList.slice());
  };

  const markGiftCardPurchaseIssued = async (purchase: IGiftCardPurchase) => {
    const purchaseId = purchase.id;
    const data = await putGiftCardPurchaseMarkIssued({ propertyId: selectedProperty, purchaseId: purchaseId });
    const index = purchaseList.findIndex(purchase => purchase.id === purchaseId);

    purchaseList[index].issuedStatus = data.issuedStatus;
    setPurchaseList(purchaseList.slice());
  };

  const handleUpdatePurchaseRecipients = async (
    purchase: IGiftCardPurchase,
    recipientsInfo: { name: string; email: string }[],
  ) => {
    try {
      setSubmitUpdateRecipientsLoading(true);
      const purchaseId = purchase.id;
      const data = await putGiftCardPurchaseUpdateRecipients({
        propertyId: selectedProperty,
        purchaseId: purchaseId,
        purchaseParams: { recipientsInfo: recipientsInfo },
      });
      const index = purchaseList.findIndex(purchase => purchase.id === purchaseId);

      purchaseList[index].orderRecipients = data.orderRecipients;
      setPurchaseList(purchaseList.slice());
      notification.withIcon('success', t('gift_card.purchase.recipients_updated'));
    } catch (error) {
      notification.withIcon('error', t('general.error_message'), t('general.error_description'));
    }

    setSubmitUpdateRecipientsLoading(false);
    closeDetailsDrawer();
  };

  const renderMenu = (purchase: IGiftCardPurchase): MenuProps => {
    const menuItems: MenuProps['items'] = [
      {
        label: t('gift_card.purchase.mark_paid'),
        key: 'mark_paid',
        disabled: isPurchasePaid(purchase),
        onClick: () => handleMarkAsPaidModal(purchase),
      },
      {
        label: t('gift_card.purchase.mark_issued'),
        key: 'mark_issued',
        disabled: arePurchaseGiftCardsIssued(purchase),
        onClick: () => handleMarkAsIssuedModal(purchase),
      },
      {
        label: t('gift_card.purchase.details'),
        key: 'details',
        onClick: () => openDetailsDrawer(purchase),
      },
    ];

    return { items: menuItems };
  };

  const renderPurchaseStatus = (status: string) => {
    return status === PURCHASE_TYPE.UNPAID ? 'unpaid' : PURCHASE_TYPE.PAID;
  };

  const renderIssuedStatus = (status: string) => {
    switch (status) {
      case PURCHASE_TYPE.PENDING:
        return t('gift_card.purchase.pending');
      case PURCHASE_TYPE.QUEUED:
        return t('gift_card.purchase.queued');
      case PURCHASE_TYPE.ISSUED:
        return t('gift_card.purchase.issued');
    }
  };

  const renderDownloadLink = (purchase: IGiftCardPurchase) => {
    return (
      <Button
        type="link"
        disabled={!arePurchaseGiftCardsIssued(purchase)}
        onClick={() => onDownloadPdfs(purchase.id, purchase.generatedId)}
      >
        {purchase.quantity}
      </Button>
    );
  };

  const tableColumns: ColumnsType<IGiftCardPurchase> = [
    {
      title: t('general.created_at'),
      dataIndex: 'createdAt',
      render: (createdAt: IGiftCardPurchase['createdAt']) => <div>{DATE_FORMATTER.toUi(createdAt)}</div>,
      sorter: (p1: IGiftCardPurchase, p2: IGiftCardPurchase) => dayjs(p1.createdAt).diff(dayjs(p2.createdAt), 'second'),
    },
    {
      title: t('gift_card.purchase_status'),
      dataIndex: 'status',
      render: (status: IGiftCardPurchase['status']) => <div>{renderPurchaseStatus(status)}</div>,
    },
    {
      title: t('gift_card.issued_status'),
      dataIndex: 'issuedStatus',
      render: (issuedStatus: IGiftCardPurchase['issuedStatus']) => <div>{renderIssuedStatus(issuedStatus)}</div>,
    },
    {
      title: t('gift_card.purchase.generated_id'),
      dataIndex: 'generatedId',
      sorter: (p1: IGiftCardPurchase, p2: IGiftCardPurchase) => p1.generatedId?.localeCompare(p2.generatedId),
    },
    {
      title: t('gift_card.purchase.quantity'),
      dataIndex: 'quantity',
      render: (_, purchase: IGiftCardPurchase) => renderDownloadLink(purchase),
      sorter: (p1: IGiftCardPurchase, p2: IGiftCardPurchase) => p1.quantity - p2.quantity,
    },
    {
      title: t('general.total_amount'),
      dataIndex: 'totalAmount',
      render: (totalAmount: IGiftCardPurchase['totalAmount']) => {
        return formatCurrencyPrice(totalAmount, property?.currency, property?.country);
      },
      sorter: (p1: IGiftCardPurchase, p2: IGiftCardPurchase) => p1.totalAmount - p2.totalAmount,
    },
    {
      title: t('general.other.email'),
      dataIndex: 'email',
      width: '11%',
      render: (_, record) => {
        return <div className={styles.ellipses}>{record.email}</div>;
      },
    },
    {
      title: t('gift_card.purchase.order_name'),
      dataIndex: 'fullName',
      render: (_, record) => {
        return <div className={styles.ellipses}>{record.fullName}</div>;
      },
    },
    {
      key: 'actions',
      render: (_, record) => (
        <Dropdown menu={renderMenu(record)}>
          <div className={styles.actions_link}>
            <span className={styles.action_link_text}>{t('general.actions')}</span> <DownOutlined />
          </div>
        </Dropdown>
      ),
    },
  ];

  const tableScroll = useMemo(() => {
    if (1200 < windowWidth) return { y: window.innerHeight - 310, x: 'max-content' };
    if (770 < windowWidth) return { y: window.innerHeight - 450, x: 'max-content' };
    return { y: window.innerHeight - 510, x: 'max-content' };
  }, [windowWidth]);

  return (
    <div data-testid="GiftCardsPurchases" className={styles.root}>
      <Table
        dataSource={purchaseList}
        columns={tableColumns}
        rowKey="id"
        pagination={pagination}
        tableLayout="fixed"
        data-testid="GiftCardPurchaseList"
        onChange={onChange}
        loading={isLoading}
        scroll={tableScroll}
      />
      <Modal
        transitionName=""
        maskTransitionName=""
        open={showConfirmationModal}
        title={t('general.modal.confirmation_question')}
        onOk={handleOkMarkAsPaid}
        onCancel={handleCancelMark}
      >
        <p>{`You are marking order ${giftCardPurchase?.generatedId} as paid.`}</p>
        <p>{t('general.modal.confirmation_direction')}</p>
      </Modal>
      <Modal
        transitionName=""
        maskTransitionName=""
        open={showConfirmationIssuedModal}
        title={t('general.modal.confirmation_question')}
        onOk={handleOkMarkAsIssued}
        onCancel={handleCancelMark}
      >
        <p>{`You are marking order ${giftCardPurchase?.generatedId} as issued.`}</p>
        <p>{`This will automatically trigger issuing of the purchased gift cards!`}</p>
        <p>{t('general.modal.confirmation_direction')}</p>
      </Modal>
      <Drawer
        destroyOnClose
        title={t('gift_card.purchase.details')}
        open={showDetailsDrawer}
        onClose={closeDetailsDrawer}
        width={window.innerWidth > 900 ? DRAWER_SIZE.LARGE : window.innerWidth}
      >
        {giftCardPurchase && (
          <GiftCardPurchaseDetailsForm
            onUpdateRecipients={handleUpdatePurchaseRecipients}
            submitLoading={submitUpdateRecipientsLoading}
            purchase={{ ...giftCardPurchase, currency: property?.currency, country: property?.country }}
          />
        )}
      </Drawer>
    </div>
  );
};

export default GiftCardPurchases;
