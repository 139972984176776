import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { Form, Button, Row, Col, Checkbox, Drawer, Tooltip } from 'antd';

import {
  IGiftCardStoreCheckoutProps,
  IGiftCardInfo,
} from 'components/gift_card_store_checkout/gift_card_store_checkout.types';

import GiftCardStoreCartInfo from './gift_card_store_cart_info';
import GiftCardStoreBillingInfo from './gift_card_store_billing_info';
import GiftCardStoreRecipientDeliveryInfo from './gift_card_store_recipient_delivery_info';

import routes from 'routing/routes';
import buildPath from 'utils/build_path';
import calculatePercentage from 'utils/calculate_percentage';
import formatCurrencyPrice from 'utils/format_currency_price';
import useTrackingCookieConsent from 'hooks/useTrackingCookieConsent';

import PageTitle from 'components/page_title/page_title';

import styles from './gift_card_store_checkout.module.scss';

const GiftCardStoreCheckout: React.FC<IGiftCardStoreCheckoutProps> = ({
  cartItems,
  property,
  storeSettings,
  onSaveGiftCardInfo,
}) => {
  const { t } = useTranslation();

  const { selectedProperty } = useParams<{ selectedProperty: string }>();

  const [billingForm] = Form.useForm();
  const [recipientForm] = Form.useForm();

  const [isSavingGiftCardInfo, setIsSavingGiftCardInfo] = useState<boolean>(false);
  const [visibleForm, setVisibleForm] = useState<string>('recipientInfoForm');
  const [showTermsAndConditions, toggleShowTermsAndConditions] = useState<boolean>(false);
  const [hasReadTermsAndConditions, setHasReadTermsAndConditions] = useState<boolean>(false);
  const isTrackingAllowed: boolean = useTrackingCookieConsent();

  const letterMailAvailable = useMemo(() => cartItems?.every(item => item.item.offerLetterDelivery), [cartItems]);
  const pickupAtHotelAvailable = useMemo(() => cartItems?.every(item => item.item.offerPickupAtHotel), [cartItems]);
  const pickupAtHotelinfo = useMemo(() => storeSettings?.allowPickupAtHotelInfo, [storeSettings]);

  const onFinish = async () => {
    const billingInfo = billingForm.getFieldsValue(true);

    const recipientInfo = recipientForm.getFieldsValue(true);
    let giftCardArray: IGiftCardInfo[] = [];

    const { deliveryInfo } = recipientInfo;

    let giftCardInfo = {
      sendByLetter: false,
      pickupAtHotel: false,
      info: '',
      name: deliveryInfo?.name || '',
    } as IGiftCardInfo;

    if (deliveryInfo?.deliveryAddress) {
      const { deliveryName, deliveryAddress, deliveryCity, deliveryZipCode, deliveryState, deliveryCountry } =
        deliveryInfo;
      giftCardInfo = {
        ...giftCardInfo,
        sendByLetter: true,
        deliveryName,
        deliveryAddress,
        deliveryCity,
        deliveryZipCode,
        deliveryState: deliveryState || '',
        deliveryCountry,
      };
    } else if (deliveryInfo?.email) {
      const { email } = deliveryInfo;
      giftCardInfo.email = email;
    } else {
      giftCardInfo.pickupAtHotel = true;
    }

    setIsSavingGiftCardInfo(true);

    const { name, address, email, phone } = billingInfo.billing;

    const nameWithoutSpaces = (name || '').trim().replace(/\s+/g, ' ');
    const [firstName = '', ...lastName] = nameWithoutSpaces.split(' ');
    const formattedLastName = lastName.length ? lastName.join(' ') : firstName;
    const formattedPhoneNumber = phone.areaCode.toString() + phone.phoneNumber.toString();

    const formattedBillingInfo = {
      address,
      firstName,
      lastName: formattedLastName,
      phone: formattedPhoneNumber,
      phoneCountryCode: phone.countryCode,
      email,
    };

    cartItems?.forEach(item => {
      const quantity = item.quantity;
      const giftCardInfoArray = Array(quantity).fill({
        ...giftCardInfo,
        giftCardTypeId: item.item.id,
        email: giftCardInfo.email || formattedBillingInfo.email,
      });
      giftCardArray = [...giftCardArray, ...giftCardInfoArray];
    });

    await onSaveGiftCardInfo({ productArray: giftCardArray, ...formattedBillingInfo });
    setTimeout(() => setIsSavingGiftCardInfo(false), 5000);
    isTrackingAllowed && window?.gtag('event', 'add_shipping_info');
  };

  const handleSaveRecipientInfo = () => {
    billingForm.getFieldsValue(true);
    setVisibleForm('paymentInfoForm');
    isTrackingAllowed && window?.gtag('event', 'begin_checkout');
  };

  const handleBuildPath = (path: string) => buildPath('', path, { selectedProperty });

  const renderGiftCards = cartItems.map(item => {
    const items = [0];
    return items.map((_, index) => {
      return (
        <Form.List name={item.item.id} key={index}>
          {() => (
            <Row
              gutter={[0, 15]}
              justify="end"
              key={index}
              data-testid="GiftCard"
              className={classNames(styles.infoWrapper, 'roundedWrapper')}
            >
              <Col sm={24} xs={24}>
                <Row align="middle" style={{ height: '100%' }} gutter={10}>
                  <Col sm={2} xs={6}>
                    <img
                      src={item.item.photos?.[0]?.publicUrl}
                      className={classNames(styles.giftCardPhoto, 'roundedImage')}
                      alt="Gift card photo"
                    />
                  </Col>
                  <Col sm={22} xs={18}>
                    <Row justify="space-between" className={classNames(styles.giftCardTitle, 'highlighted')}>
                      <Col sm={10} xs={24}>
                        <div className={styles.giftCardTitleContent}>
                          {item.item.title} {`( x ${item.quantity} )`}
                        </div>
                      </Col>
                      <Col sm={14} xs={24}>
                        <Row gutter={10} align="middle" justify="end">
                          {item.item.netPrice !== item.item.price && (
                            <Col className="discountPrice">
                              {formatCurrencyPrice(item.item.price, property?.currency, property?.country)}
                            </Col>
                          )}
                          <Col>{formatCurrencyPrice(item.item.netPrice, property?.currency, property?.country)}</Col>
                          {item.item.netPrice !== item.item.price &&
                            calculatePercentage(item.item.netPrice, item.item.price) && (
                              <Col className={classNames('discountPercentage', styles.discountPercentage)}>
                                {calculatePercentage(item.item.netPrice, item.item.price)}
                              </Col>
                            )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Form.List>
      );
    });
  });

  return (
    <>
      {visibleForm === 'recipientInfoForm' ? (
        <PageTitle
          steps={[
            { title: t('general.home'), link: handleBuildPath(routes.giftCardStorePage) },
            { title: t('gift_card.basket'), link: handleBuildPath(routes.giftCardStoreCartPage) },
            { title: t('general.proceed_to_purchase') },
          ]}
        />
      ) : (
        <PageTitle
          steps={[
            { title: t('general.home'), link: handleBuildPath(routes.giftCardStorePage) },
            { title: t('gift_card.basket'), link: handleBuildPath(routes.giftCardStoreCartPage) },
            { title: t('general.payment_info') },
          ]}
        />
      )}
      <div className={styles.root} data-testid="GiftCardStoreCheckout">
        <div className={classNames(styles.header, 'highlighted')}>
          {visibleForm === 'recipientInfoForm' ? t('general.recipient_info') : t('general.payment_info')}
        </div>

        {visibleForm === 'paymentInfoForm' && (
          <>
            <Form onFinish={onFinish} layout="vertical" form={billingForm}>
              <div className={classNames(styles.infoWrapper, 'roundedWrapper', styles.paymentInfoWrapper)}>
                <GiftCardStoreBillingInfo form={billingForm} />
                <GiftCardStoreCartInfo cartItems={cartItems} property={property} />
              </div>
              {storeSettings?.termsAndConditions && (
                <div className={styles.readTerms}>
                  <Checkbox
                    checked={hasReadTermsAndConditions}
                    onChange={e => setHasReadTermsAndConditions(e.target.checked)}
                  />
                  <span className={styles.agreeTerms} onClick={() => toggleShowTermsAndConditions(true)}>
                    {t('general.read_terms_and_conditions')}
                  </span>
                </div>
              )}
              <div className={styles.submitWrapper}>
                <Tooltip
                  title={
                    hasReadTermsAndConditions || !storeSettings?.termsAndConditions
                      ? ''
                      : t('general.check_terms_and_conditions_to_proceed')
                  }
                >
                  <div className={styles.continueButton}>
                    <Button
                      size="large"
                      htmlType="submit"
                      className={classNames(styles.continueButton, 'blackButton')}
                      loading={isSavingGiftCardInfo}
                      disabled={
                        isSavingGiftCardInfo || (!hasReadTermsAndConditions && !!storeSettings?.termsAndConditions)
                      }
                    >
                      {t('general.continue_to_payment')}
                    </Button>
                  </div>
                </Tooltip>
              </div>
            </Form>
            <Drawer
              open={showTermsAndConditions}
              title={t('general.terms_and_conditions')}
              onClose={() => toggleShowTermsAndConditions(false)}
            >
              <div>{storeSettings?.termsAndConditions}</div>
            </Drawer>
          </>
        )}
        {visibleForm === 'recipientInfoForm' && (
          <Form
            onFinish={handleSaveRecipientInfo}
            initialValues={{ deliveryInfo: { deliveryCountry: 'Iceland' } }}
            layout="vertical"
            form={recipientForm}
          >
            <Row gutter={[16, 16]}>
              <Col md={14}>{renderGiftCards}</Col>
              <Col md={10}>
                <GiftCardStoreRecipientDeliveryInfo
                  showPost={letterMailAvailable}
                  showAtHotel={pickupAtHotelAvailable}
                  pickupAtHotelinfo={pickupAtHotelinfo}
                />
              </Col>
            </Row>
            <div className={styles.submitWrapper}>
              <Button
                size="large"
                className={classNames(styles.continueButton, 'blackButton', 'highlighted')}
                htmlType="submit"
              >
                {t('general.continue_payment')}
              </Button>
            </div>
          </Form>
        )}
      </div>
    </>
  );
};

export default GiftCardStoreCheckout;
