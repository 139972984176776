import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import classNames from 'classnames';

import formatCurrencyPrice from 'utils/format_currency_price';
import { IGiftCardStoreCartInfoProps } from './gift_card_store_cart_info.types';
import styles from './gift_card_store_cart_info.module.scss';

const GiftCardStoreCartInfo: React.FC<IGiftCardStoreCartInfoProps> = ({ cartItems, property }) => {
  const { t } = useTranslation();

  const discountPrice = useMemo(
    () => cartItems.map(item => item.item.netPrice * item.quantity).reduce((prev, curr) => prev + curr, 0),
    [cartItems],
  );

  const totalPrice = useMemo(
    () => cartItems.map(item => item.item.price * item.quantity).reduce((prev, curr) => prev + curr, 0),
    [cartItems],
  );

  const renderItems = useMemo(
    () =>
      cartItems.map((item, index) => {
        return (
          <Row key={index} justify="space-between" className={styles.cartItem} data-testid="GiftCardInfo">
            <Col>
              {item.item.title} <span>{` x ${item.quantity}`}</span>
            </Col>
            <Row gutter={10} align="middle">
              {item.item.price !== item.item.netPrice && (
                <Col className={classNames('discountPrice', styles.discountPrice)}>
                  {formatCurrencyPrice(item.item.price, property?.currency, property?.country)}
                </Col>
              )}
              <Col className={classNames(styles.cartItemPrice, 'highlighted')}>
                {formatCurrencyPrice(item.item.netPrice, property?.currency, property?.country)}
              </Col>
            </Row>
          </Row>
        );
      }),
    [cartItems, property],
  );

  return (
    <div data-testid="GiftCardStoreCartInfo">
      {renderItems}
      <Row justify="space-between" className={classNames(styles.total, 'highlighted')}>
        <Col>{t('hotel_page.total')}</Col>
        <Row gutter={10} align="middle">
          {totalPrice !== discountPrice && (
            <Col className="discountPrice">
              {formatCurrencyPrice(totalPrice, property?.currency, property?.country)}
            </Col>
          )}
          <Col className={styles.totalPrice}>
            {formatCurrencyPrice(discountPrice, property?.currency, property?.country)}
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default GiftCardStoreCartInfo;
