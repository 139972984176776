import { IUser } from 'components/admin/users/users_list/users_list.types';

const init = (user: IUser): void => {
  if (window.location.host !== 'app.reserva.is') return;

  if (!window?.fcWidget) {
    window.fcWidgetMessengerConfig = {
      firstName: ' ',
      lastName: user.name,
      email: user.email,
    };

    const freshChatScript = document.createElement('script');
    freshChatScript.setAttribute('type', 'text/javascript');
    freshChatScript.setAttribute('src', '//fw-cdn.com/1430544/2394900.js');
    freshChatScript.setAttribute('widgetId', 'a502af06-c120-4724-a600-f98c6cefc4af');
    document.body.appendChild(freshChatScript);
  }
};

const isFreshChatUserInitiated = async () => {
  try {
    const data = await window?.fcWidget.user.isExists();
    if (data && data.data === true) {
      return Promise.resolve(true);
    }
  } catch (error) {
    console.error('Error fetching if Freshchat user is initiated', error);
    return Promise.resolve(false);
  }
};

const clearFreshChatUser = async () => {
  try {
    const data = await window?.fcWidget.user.clear();
    return Promise.resolve(data);
  } catch (error) {
    console.error('Error fetching Freshchat user', error);
    return Promise.resolve(false);
  }
};

const clearFreshChatUserData = async () => {
  try {
    const isInitiated = await isFreshChatUserInitiated();

    if (isInitiated) {
      const clearData = await clearFreshChatUser();
      return Promise.resolve(clearData);
    } else {
      return Promise.resolve(false);
    }
  } catch (error) {
    console.error('Error fetching if Freshchat user is initiated', error);
    return Promise.resolve(false);
  }
};

const reset = async (): Promise<boolean | { success: boolean }> => {
  const isUserDataCleared = await clearFreshChatUserData();
  return isUserDataCleared;
};

export default {
  init,
  reset,
};
