// Generated with util/create-component.js
import React, { useState, useEffect, useMemo } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Input,
  Select,
  Button,
  InputNumber,
  Upload,
  Popconfirm,
  Tooltip,
  notification,
  Radio,
  UploadFile,
  Cascader,
  Table,
  Modal,
  DatePicker,
  Space,
} from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import { DeleteOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Editor } from 'react-draft-wysiwyg';
import 'draft-js/dist/Draft.css';

import GIFTCARD_STATUS from 'constants/gift_card_status';
import { currencyMap } from 'constants/currency_map';

import Loading from 'components/loading';

import styles from './gift_card_form.module.scss';
import { IGiftCardFormProps, IPhoto, IOption, IUpsellItem } from './gift_card_form.types';
import { IStoreSettings } from 'components/gift_cards_store_settings_form/gift_cards_store_settings_form.types';

const { SHOW_CHILD } = Cascader;

const editorStyle = {
  color: 'black',
  backgroundColor: 'white',
  minHeight: '200px',
  maxHeight: '200px',
  padding: '0 1em',
  borderRadius: '2px',
  border: '1px solid #d9d9d9',
};

const toolbarStyle = {
  border: '1px solid #d9d9d9',
};

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
};

const initialValues = {
  giftcardType: 0,
  title: null,
  price: null,
  description: null,
  expirationType: 0,
  expirationDuration: 48,
  expirationDurationUnit: 'months',
  absoluteExpirationDate: null,
  status: GIFTCARD_STATUS.CREATED,
  limit: null,
  photos: [],
  settings: {
    lengthOfStay: 1,
    reservationValue: 0,
  },
  multiPropertyId: null,
  ratePlanIds: null,
  netPrice: null,
  ratePlans: null,
  upsellTable: null,
  offerLetterDelivery: false,
  offerPickupAtHotel: false,
};

const GiftCardForm: React.FC<IGiftCardFormProps> = ({
  giftCard,
  isLoadingRatePlans,
  onDeletePhoto,
  userHotelRoomTypeRatePlans,
  groupStores,
  form,
  onUpdatePhotos,
  photos,
  allStoresSupportPickup,
  onSetEditorState,
  editorState,
  allUpsellOptions,
  allOptions,
  onValuesChange,
  onMultiPropertyChange,
  currency,
}) => {
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCascade, setSelectedCascade] = useState<IOption[]>([]);
  const [cascaderValue, setCascaderValue] = useState<string[]>([]);

  if (!form) return null;
  const columns = [
    {
      title: t('gift_card.hotel'),
      dataIndex: 'hotel',
      key: 'hotel',
    },
    {
      title: t('gift_card.room_type'),
      dataIndex: 'roomType',
      key: 'roomType',
    },
    {
      title: t('gift_card.rate_plan'),
      dataIndex: 'ratePlan',
      key: 'ratePlan',
    },
    {
      title: t('gift_card.price'),
      dataIndex: 'price',
      key: 'price',
      render: (_: number | undefined, __: IUpsellItem, index: number) => (
        <Form.Item
          name={[index, 'price']}
          rules={[...requiredRules, { type: 'number', min: 0, message: t('general.enter_valid_price') }]}
          noStyle
        >
          <InputNumber min={0} addonAfter={currencyMap[currency]} />
        </Form.Item>
      ),
    },
    {
      title: t('gift_card.action'),
      dataIndex: 'action',
      render: (_: string, __: IUpsellItem, index: number) => (
        <Button onClick={() => handleDelete(index)} block icon={<DeleteOutlined />}></Button>
      ),
    },
  ];

  const storeOptions = useMemo(() => {
    const options = groupStores?.map((gs: IStoreSettings) => ({ value: gs.id, label: gs.name }));
    return options;
  }, [groupStores]);

  const handleDelete = (index: number) => {
    const items = form.getFieldValue('upsellTable');

    const newItems = items.filter((_: IUpsellItem, itemIndex: number) => itemIndex !== index);

    form.setFieldsValue({ upsellTable: newItems });
    form.validateFields(['ratePlans']);
  };

  const isSelectedRoomType = (selection: string[]) => {
    const ratePlanSelections = form.getFieldValue('ratePlans');

    if (ratePlanSelections) {
      return ratePlanSelections?.some(
        (propertyRate: string[]) => propertyRate[0] === selection[0] && propertyRate[1] === selection[1],
      );
    }

    return false;
  };

  const handleOk = () => {
    if (selectedCascade.length === 0) {
      setIsModalVisible(false);
      setSelectedCascade([]);
      setCascaderValue([]);
    }

    const tableItems = form.getFieldValue('upsellTable') || [];

    const existingSelection = tableItems.find(
      (rateSelection: IUpsellItem) =>
        rateSelection.propertyId === selectedCascade[0].value && rateSelection.roomTypeId === selectedCascade[1].value,
    );

    if (existingSelection || selectedCascade.length < 2 || isSelectedRoomType(cascaderValue)) {
      setIsModalVisible(false);
      setSelectedCascade([]);
      setCascaderValue([]);

      return;
    }
    form.setFieldsValue({
      upsellTable: [
        ...(form.getFieldValue('upsellTable') || []),
        {
          hotel: selectedCascade[0].label,
          roomType: selectedCascade[1].label,
          ratePlan: selectedCascade[2].label,
          price: undefined,
          propertyId: selectedCascade[0].value,
          roomTypeId: selectedCascade[1].value,
          ratePlanId: selectedCascade[2].value,
        },
      ],
    });

    setIsModalVisible(false);
    setSelectedCascade([]);
    setCascaderValue([]);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const handleChangeUpsellRate = (value: any, selectedOptions: IOption[]) => {
    setSelectedCascade(selectedOptions);
    setCascaderValue(value as string[]);
  };

  const reservationValues = [
    { value: 0, label: '0' },
    { value: 1, label: t('gift_card.value') },
  ];

  const giftcardType = Form.useWatch('giftcardType', form);

  const price = Form.useWatch('price', form);
  useEffect(() => {
    form.validateFields(['netPrice']);
  }, [price]);

  const openNotificationWithIcon = (type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: `Gift card successfully ${giftCard ? 'updated' : 'created'}.`,
      });
    } else {
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
  };

  const handleUpdatePhotos = (photos: IPhoto[]) => {
    const p = photos;
    onUpdatePhotos(p);
    form.setFieldsValue({ photos: p });
  };

  const handlePhotoRemove = async (photo: IPhoto) => {
    const giftCardValues = form.getFieldsValue(true);
    const ind = photos.findIndex(p => p.publicUrl === photo.publicUrl);
    const updatedPhotos = photos;
    if (ind === -1) return;
    if (photo?.id) {
      try {
        await onDeletePhoto(giftCardValues, photo);
        openNotificationWithIcon('success');
      } catch (e) {
        openNotificationWithIcon('error');
      }
    }
    updatedPhotos.splice(ind, 1);
    handleUpdatePhotos(updatedPhotos);
  };

  const handlePreviewPhoto = async (photo: File) => {
    const updatedPhotos = photos;
    updatedPhotos.push({
      publicUrl: URL.createObjectURL(photo),
      isRemoved: false,
      file: photo,
    });

    handleUpdatePhotos(updatedPhotos);
    return false;
  };

  const renderPhoto = (photo: IPhoto) => {
    return (
      <div
        data-testid="GiftCardPhoto"
        className={styles.gift_card_photo_container}
        onDragOver={event => event.preventDefault()}
      >
        <Tooltip title={t('photo.remove_photo_title')}>
          <Popconfirm
            title={
              <>
                <p>{t('general.confirm')}</p>
                <p>{t('photo.remove_photo')}</p>
              </>
            }
            cancelText={t('link.cancel')}
            okText={t('general.yes')}
            placement="bottomRight"
            onConfirm={() => handlePhotoRemove(photo)}
          >
            <div data-testid="GiftCardPhotoDelete" className={styles.delete_photo_button}>
              <DeleteOutlined />
            </div>
          </Popconfirm>
        </Tooltip>
        <img src={photo.publicUrl} className={styles.gift_card_photo} alt="Gift Card" />
      </div>
    );
  };

  if (isLoadingRatePlans) {
    return (
      <div className={styles.spinner_container} data-testid="LoadingSpinner">
        <Loading />
      </div>
    );
  }

  const requiredRules = [{ required: true, message: t('validation_messages.required') }];

  const statusOptions = [
    { value: GIFTCARD_STATUS.CREATED, label: t('general.no') },
    { value: GIFTCARD_STATUS.PUBLISHED, label: t('general.yes') },
  ];

  const offerLetterdeliveryOptions = [
    { value: false, label: t('general.no') },
    { value: true, label: t('general.yes') },
  ];

  const giftcardTypeOptions = [
    { value: 0, label: t('gift_card.single_property') },
    { value: 1, label: t('gift_card.multi_property'), disabled: !!(giftCard || userHotelRoomTypeRatePlans.length < 2) },
    { value: 2, label: t('gift_card.no_property') },
  ];

  const expirationTypeOptions = [
    { value: 0, label: t('gift_card.expiration_type_period') },
    { value: 1, label: t('gift_card.expiration_type_date') },
  ];

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const validateCascader = (rule: any, value: string[]): Promise<void> => {
    if (!value || !value.length) {
      return Promise.resolve();
    }

    const tableItems = form.getFieldValue('upsellTable') || [];
    const selectedValues = new Set<string>();

    tableItems.forEach((item: IUpsellItem) => {
      if (item.roomTypeId) {
        selectedValues.add(item.roomTypeId);
      }
    });

    for (const option of value) {
      if (option.length !== 3) {
        return Promise.reject(t('gift_card.select_one_per_room_type'));
      }

      if (selectedValues.has(option[1])) {
        return Promise.reject(t('gift_card.select_one_per_room_type'));
      }
      selectedValues.add(option[1]);
    }

    return Promise.resolve();
  };

  const displayRender = (labels: string[], selectedOptions: IOption[] | undefined) => {
    if (!selectedOptions || selectedOptions[0] == undefined) {
      return;
    }
    return (
      <div>
        {labels.map((label, i) => {
          const option = selectedOptions[i];
          if (i === labels.length - 1) {
            return <span key={option.value}>{label}</span>;
          }

          return <span key={option.value}>{label} / </span>;
        })}
      </div>
    );
  };

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current?.isBefore(dayjs().endOf('day'));
  };

  return (
    <div data-testid="GiftCardForm" className={styles.root}>
      <Form
        name="GiftCardForm"
        form={form}
        onValuesChange={onValuesChange}
        initialValues={{ ...initialValues }}
        {...formItemLayout}
      >
        <Form.Item
          name="giftcardType"
          rules={requiredRules}
          label={t('gift_card.gift_card_type')}
          tooltip={t('gift_card.gift_card_type_tooltip')}
        >
          <Radio.Group
            options={giftcardTypeOptions}
            optionType="button"
            buttonStyle="outline"
            data-testid="MultiProperty"
            value={giftcardType}
            onChange={onMultiPropertyChange}
            disabled={giftCard && !giftCard?.isDuplicate}
          />
        </Form.Item>
        <Form.Item
          name="title"
          rules={[...requiredRules, { max: 80 }]}
          label={t('gift_card.title')}
          tooltip={t('gift_card.title_tooltip')}
        >
          <Input placeholder={t('gift_card.title')} />
        </Form.Item>
        <Form.Item
          name="price"
          rules={[...requiredRules, { type: 'number', min: 0, message: t('general.enter_valid_price') }]}
          label={t('gift_card.price')}
          tooltip={t('gift_card.price_tooltip')}
        >
          <InputNumber
            controls={false}
            addonAfter={currencyMap[currency]}
            placeholder={t('bookings_list.price_title')}
            onChange={() => form.validateFields(['netPrice'])}
          />
        </Form.Item>
        <Form.Item
          name="netPrice"
          rules={[
            { type: 'number', min: 1, message: t('general.enter_valid_price') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value && getFieldValue('price') && value < getFieldValue('price')) {
                  return Promise.resolve();
                } else if (!value) {
                  return Promise.resolve();
                }
                return Promise.reject(t('gift_card.discount_validation_error'));
              },
            }),
          ]}
          label={t('gift_card.discount_label')}
          tooltip={t('gift_card.net_price_tooltip')}
        >
          <InputNumber
            controls={false}
            addonAfter={currencyMap[currency]}
            placeholder={t('gift_card.optional_discount')}
          />
        </Form.Item>
        <Form.Item
          name="ratePlans"
          rules={[
            { required: [0, 1].includes(giftcardType), message: t('validation_messages.required') },
            { validator: validateCascader },
          ]}
          label={t('gift_card.select_rate')}
          tooltip={t('gift_card.rate_plans_tooltip')}
          style={{ display: giftcardType === 1 || giftcardType === 0 ? 'block' : 'none' }}
        >
          <Cascader
            placeholder={t('gift_card.select_rate')}
            data-testid="RatePlans"
            options={allOptions}
            disabled={false}
            multiple={true}
            displayRender={displayRender}
            showCheckedStrategy={SHOW_CHILD}
          ></Cascader>
        </Form.Item>
        <Form.Item
          label={t('gift_card.select_upsell_rate')}
          tooltip={t('gift_card.upsell_rate_plans_tooltip')}
          style={{ display: giftcardType === 1 || giftcardType === 0 ? 'block' : 'none' }}
        >
          <Form.List name="upsellTable">
            {fields => (
              <>
                <Table
                  dataSource={fields.map((field, index) => ({
                    ...form.getFieldValue(['upsellTable', field.name]),
                    key: index,
                  }))}
                  columns={columns}
                  data-testid="UpsellTable"
                  pagination={false}
                  rowKey="name"
                  footer={() => (
                    <Button type="dashed" onClick={() => setIsModalVisible(true)} block icon={<PlusOutlined />}>
                      {t('gift_card.add_upsell')}
                    </Button>
                  )}
                />

                <Modal
                  title={t('gift_card.select_upsell_rate')}
                  open={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <Cascader
                    value={cascaderValue}
                    options={allUpsellOptions}
                    onChange={handleChangeUpsellRate}
                    placeholder={t('gift_card.select_upsell_rate')}
                  />
                </Modal>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item
          name="storeIds"
          label={t('general.store')}
          rules={requiredRules}
          tooltip={t('gift_card.store_tooltip')}
        >
          <Select
            mode="multiple"
            className={styles.full_width}
            placeholder={t('gift_card.select_store')}
            options={storeOptions}
            data-testid="StoreIdsValue"
          />
        </Form.Item>
        <Form.Item
          name={['settings', 'reservationValue']}
          rules={[{ required: giftcardType !== 2, message: t('validation_messages.required') }]}
          label={t('gift_card.reservation_value')}
          tooltip={t('gift_card.reservation_value_tooltip')}
        >
          <Select
            disabled={giftcardType === 2}
            placeholder={t('gift_card.reservation_value')}
            data-testid="ReservationValue"
            options={reservationValues}
          ></Select>
        </Form.Item>
        <Form.Item
          name={['settings', 'lengthOfStay']}
          rules={[
            { required: giftcardType !== 2, message: t('validation_messages.required') },
            { type: 'number', min: 1, message: t('gift_card.enter_valid_length_of_stay') },
          ]}
          label={t('gift_card.length_of_stay')}
          tooltip={t('gift_card.length_of_stay_tooltip')}
        >
          <InputNumber disabled={giftcardType === 2} controls={false} placeholder={t('gift_card.length_of_stay')} />
        </Form.Item>

        <Form.Item
          name="limit"
          rules={[...requiredRules, { type: 'number', min: 1, message: t('general.enter_valid_inventory') }]}
          label={t('link.inventory')}
          tooltip={t('gift_card.limit_tooltip')}
        >
          <InputNumber controls={false} placeholder={t('link.inventory')} />
        </Form.Item>

        <Form.Item
          name="expirationType"
          rules={requiredRules}
          label={t('gift_card.expiration_type')}
          tooltip={t('gift_card.expiration_type_tooltip')}
        >
          <Radio.Group
            options={expirationTypeOptions}
            optionType="button"
            buttonStyle="outline"
            data-testid="ExpirationType"
            onChange={e => {
              if (e.target.value === 0) {
                form.setFieldValue('absoluteExpirationDate', null);
              }
            }}
          />
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.expirationType !== currentValues.expirationType}
        >
          {({ getFieldValue }) => (
            <Form.Item
              label={t('gift_card.validity')}
              tooltip={t('gift_card.validity_tooltip')}
              hidden={getFieldValue('expirationType') === 1}
            >
              <Space.Compact block direction="horizontal">
                <Form.Item name="expirationDuration" rules={requiredRules} label={t('gift_card.validity')} noStyle>
                  <InputNumber
                    data-testid="ExpirationDuration"
                    controls={false}
                    placeholder={t('gift_card.validity')}
                    className={styles.expiration_duration}
                  />
                </Form.Item>
                <Form.Item name="expirationDurationUnit" noStyle rules={requiredRules}>
                  <Select data-testid="ExpirationDurationUnit" className={styles.expiration_duration_unit}>
                    <Select.Option value="hours">{t('gift_card.hours')}</Select.Option>
                    <Select.Option value="days">{t('gift_card.days')}</Select.Option>
                    <Select.Option value="months">{t('gift_card.months')}</Select.Option>
                  </Select>
                </Form.Item>
              </Space.Compact>
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.expirationType !== currentValues.expirationType}
        >
          {({ getFieldValue }) => (
            <Form.Item
              name="absoluteExpirationDate"
              label={t('gift_card.absolute_expiration_date')}
              tooltip={t('gift_card.absolute_expiration_date_tooltip')}
              hidden={getFieldValue('expirationType') === 0}
              rules={[{ required: getFieldValue('expirationType') === 1, message: t('validation_messages.required') }]}
            >
              <DatePicker disabledDate={disabledDate} />
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item
          name="offerLetterDelivery"
          rules={requiredRules}
          label={t('gift_card.offer_letter_delivery')}
          tooltip={t('gift_card.offer_letter_delivery_tooltip')}
        >
          <Radio.Group
            options={offerLetterdeliveryOptions}
            optionType="button"
            buttonStyle="outline"
            data-testid="OfferLetterDelivery"
          />
        </Form.Item>
        <Form.Item
          name="offerPickupAtHotel"
          rules={requiredRules}
          label={t('gift_card.offer_pickup_at_hotel')}
          tooltip={t('gift_card.offer_pickup_at_hotel_tooltip')}
        >
          <Radio.Group
            options={offerLetterdeliveryOptions}
            optionType="button"
            buttonStyle="outline"
            data-testid="OfferPickupAtHotel"
            disabled={!allStoresSupportPickup}
          />
        </Form.Item>
        <Form.Item
          name="description"
          validateTrigger="onBlur"
          rules={requiredRules}
          label={t('general.description')}
          tooltip={t('gift_card.description_tooltip')}
        >
          <Editor
            editorState={editorState}
            toolbar={{
              options: ['inline', 'list', 'textAlign'],
              inline: {
                options: ['bold', 'italic', 'underline'],
              },
            }}
            editorStyle={editorStyle}
            toolbarStyle={toolbarStyle}
            onEditorStateChange={onSetEditorState}
            stripPastedStyles={true}
          />
        </Form.Item>
        {(!giftCard || giftCard?.isDuplicate) && (
          <Form.Item
            name="status"
            rules={requiredRules}
            label={t('gift_card.publish_when_created')}
            tooltip={t('gift_card.publish_when_created_tooltip')}
          >
            <Radio.Group
              options={statusOptions}
              optionType="button"
              buttonStyle="outline"
              data-testid="PublishWhenCreated"
            />
          </Form.Item>
        )}
        <legend>{t('general.photos')}</legend>
        <Form.Item name="photos" noStyle tooltip={t('gift_card.photos_tooltip')}>
          <Upload
            beforeUpload={handlePreviewPhoto}
            name="giftCardPhotos"
            itemRender={(originNode, file) => renderPhoto(file)}
            listType="picture-card"
            multiple={true}
            fileList={photos as UploadFile<IPhoto>[]}
            accept={'.png,.jpeg,.jpg'}
          >
            <div className={styles.add_new_photo}>
              <PlusCircleOutlined className={styles.add_photo} />
              <div>{t('photo.add_new')}</div>
            </div>
          </Upload>
        </Form.Item>
      </Form>
    </div>
  );
};

export default GiftCardForm;
