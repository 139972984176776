export default [
  { label: 'Afghanistan', languageCode: 'fa-AF', value: 'AF' },
  { label: 'Albania', languageCode: 'sq-AL', value: 'AL' },
  { label: 'Algeria', languageCode: 'ar-DZ', value: 'DZ' },
  { label: 'American Samoa', languageCode: 'en-AS', value: 'AS' },
  { label: 'Andorra', languageCode: 'ca-AD', value: 'AD' },
  { label: 'Angola', languageCode: 'pt-AO', value: 'AO' },
  { label: 'Anguilla', languageCode: 'en-AI', value: 'AI' },
  { label: 'Antarctica', languageCode: 'en-001', value: 'AQ' },
  { label: 'Antigua and Barbuda', languageCode: 'en-AG', value: 'AG' },
  { label: 'Argentina', languageCode: 'es-AR', value: 'AR' },
  { label: 'Armenia', languageCode: 'hy-AM', value: 'AM' },
  { label: 'Aruba', languageCode: 'nl-AW', value: 'AW' },
  { label: 'Australia', languageCode: 'en-AU', value: 'AU' },
  { label: 'Austria', languageCode: 'de-AT', value: 'AT' },
  { label: 'Azerbaijan', languageCode: 'az-AZ', value: 'AZ' },
  { label: 'Bahamas', languageCode: 'en-BS', value: 'BS' },
  { label: 'Bahrain', languageCode: 'ar-BH', value: 'BH' },
  { label: 'Bangladesh', languageCode: 'bn-BD', value: 'BD' },
  { label: 'Barbados', languageCode: 'en-BB', value: 'BB' },
  { label: 'Belarus', languageCode: 'be-BY', value: 'BY' },
  { label: 'Belgium', languageCode: 'nl-BE', value: 'BE' },
  { label: 'Belize', languageCode: 'en-BZ', value: 'BZ' },
  { label: 'Benin', languageCode: 'fr-BJ', value: 'BJ' },
  { label: 'Bermuda', languageCode: 'en-BM', value: 'BM' },
  { label: 'Bhutan', languageCode: 'dz-BT', value: 'BT' },
  { label: 'Bolivia', languageCode: 'es-BO', value: 'BO' },
  { label: 'Bosnia and Herzegovina', languageCode: 'bs-BA', value: 'BA' },
  { label: 'Botswana', languageCode: 'en-BW', value: 'BW' },
  { label: 'Bouvet Island', languageCode: 'en-001', value: 'BV' },
  { label: 'Brazil', languageCode: 'pt-BR', value: 'BR' },
  { label: 'British Indian Ocean Territory', languageCode: 'en-001', value: 'IO' },
  { label: 'Brunei Darussalam', languageCode: 'ms-BN', value: 'BN' },
  { label: 'Bulgaria', languageCode: 'bg-BG', value: 'BG' },
  { label: 'Burkina Faso', languageCode: 'fr-BF', value: 'BF' },
  { label: 'Burundi', languageCode: 'fr-BI', value: 'BI' },
  { label: 'Cabo Verde', languageCode: 'pt-CV', value: 'CV' },
  { label: 'Cambodia', languageCode: 'km-KH', value: 'KH' },
  { label: 'Cameroon', languageCode: 'fr-CM', value: 'CM' },
  { label: 'Canada', languageCode: 'en-CA', value: 'CA' },
  { label: 'Cayman Islands', languageCode: 'en-KY', value: 'KY' },
  { label: 'Central African Republic', languageCode: 'fr-CF', value: 'CF' },
  { label: 'Chad', languageCode: 'fr-TD', value: 'TD' },
  { label: 'Chile', languageCode: 'es-CL', value: 'CL' },
  { label: 'China', languageCode: 'zh-CN', value: 'CN' },
  { label: 'Christmas Island', languageCode: 'en-001', value: 'CX' },
  { label: 'Cocos (Keeling) Islands', languageCode: 'en-CC', value: 'CC' },
  { label: 'Colombia', languageCode: 'es-CO', value: 'CO' },
  { label: 'Comoros', languageCode: 'ar-KM', value: 'KM' },
  { label: 'Congo', languageCode: 'fr-CG', value: 'CG' },
  { label: 'Congo, Democratic Republic', languageCode: 'fr-CD', value: 'CD' },
  { label: 'Cook Islands', languageCode: 'en-CK', value: 'CK' },
  { label: 'Costa Rica', languageCode: 'es-CR', value: 'CR' },
  { label: 'Croatia', languageCode: 'hr-HR', value: 'HR' },
  { label: 'Cuba', languageCode: 'es-CU', value: 'CU' },
  { label: 'Cyprus', languageCode: 'el-CY', value: 'CY' },
  { label: 'Czech Republic', languageCode: 'cs-CZ', value: 'CZ' },
  { label: 'Denmark', languageCode: 'da-DK', value: 'DK' },
  { label: 'Djibouti', languageCode: 'fr-DJ', value: 'DJ' },
  { label: 'Dominica', languageCode: 'en-DM', value: 'DM' },
  { label: 'Dominican Republic', languageCode: 'es-DO', value: 'DO' },
  { label: 'Ecuador', languageCode: 'es-EC', value: 'EC' },
  { label: 'Egypt', languageCode: 'ar-EG', value: 'EG' },
  { label: 'El Salvador', languageCode: 'es-SV', value: 'SV' },
  { label: 'Equatorial Guinea', languageCode: 'es-GQ', value: 'GQ' },
  { label: 'Eritrea', languageCode: 'ti-ER', value: 'ER' },
  { label: 'Estonia', languageCode: 'et-EE', value: 'EE' },
  { label: 'Ethiopia', languageCode: 'am-ET', value: 'ET' },
  { label: 'Falkland Islands (Malvinas)', languageCode: 'en-FK', value: 'FK' },
  { label: 'Faroe Islands', languageCode: 'fo-FO', value: 'FO' },
  { label: 'Fiji', languageCode: 'en-FJ', value: 'FJ' },
  { label: 'Finland', languageCode: 'fi-FI', value: 'FI' },
  { label: 'France', languageCode: 'fr-FR', value: 'FR' },
  { label: 'French Guiana', languageCode: 'fr-GF', value: 'GF' },
  { label: 'French Polynesia', languageCode: 'fr-PF', value: 'PF' },
  { label: 'French Southern Territories', languageCode: 'fr-001', value: 'TF' },
  { label: 'Gabon', languageCode: 'fr-GA', value: 'GA' },
  { label: 'Gambia', languageCode: 'en-GM', value: 'GM' },
  { label: 'Georgia', languageCode: 'ka-GE', value: 'GE' },
  { label: 'Germany', languageCode: 'de-DE', value: 'DE' },
  { label: 'Ghana', languageCode: 'ak-GH', value: 'GH' },
  { label: 'Gibraltar', languageCode: 'en-GI', value: 'GI' },
  { label: 'Greece', languageCode: 'el-GR', value: 'GR' },
  { label: 'Greenland', languageCode: 'kl-GL', value: 'GL' },
  { label: 'Grenada', languageCode: 'en-GD', value: 'GD' },
  { label: 'Guadeloupe', languageCode: 'fr-GP', value: 'GP' },
  { label: 'Guam', languageCode: 'en-GU', value: 'GU' },
  { label: 'Guatemala', languageCode: 'es-GT', value: 'GT' },
  { label: 'Guernsey', languageCode: 'en-GG', value: 'GG' },
  { label: 'Guinea', languageCode: 'fr-GN', value: 'GN' },
  { label: 'Guinea-Bissau', languageCode: 'pt-GW', value: 'GW' },
  { label: 'Guyana', languageCode: 'en-GY', value: 'GY' },
  { label: 'Haiti', languageCode: 'fr-HT', value: 'HT' },
  { label: 'Heard Island and Mcdonald Islands', languageCode: 'en-001', value: 'HM' },
  { label: 'Holy See (Vatican City State)', languageCode: 'it-VA', value: 'VA' },
  { label: 'Honduras', languageCode: 'es-HN', value: 'HN' },
  { label: 'Hong Kong', languageCode: 'zh-HK', value: 'HK' },
  { label: 'Hungary', languageCode: 'hu-HU', value: 'HU' },
  { label: 'Iceland', languageCode: 'de-DE', value: 'IS' },
  { label: 'India', languageCode: 'hi-IN', value: 'IN' },
  { label: 'Indonesia', languageCode: 'id-ID', value: 'ID' },
  { label: 'Iran', languageCode: 'fa-IR', value: 'IR' },
  { label: 'Iraq', languageCode: 'ar-IQ', value: 'IQ' },
  { label: 'Ireland', languageCode: 'en-IE', value: 'IE' },
  { label: 'Isle of Man', languageCode: 'en-IM', value: 'IM' },
  { label: 'Israel', languageCode: 'he-IL', value: 'IL' },
  { label: 'Italy', languageCode: 'it-IT', value: 'IT' },
  { label: 'Jamaica', languageCode: 'en-JM', value: 'JM' },
  { label: 'Japan', languageCode: 'ja-JP', value: 'JP' },
  { label: 'Jersey', languageCode: 'en-JE', value: 'JE' },
  { label: 'Jordan', languageCode: 'ar-JO', value: 'JO' },
  { label: 'Kazakhstan', languageCode: 'kk-KZ', value: 'KZ' },
  { label: 'Kenya', languageCode: 'sw-KE', value: 'KE' },
  { label: 'Kiribati', languageCode: 'en-KI', value: 'KI' },
  { label: 'Korea (North)', languageCode: 'ko-KP', value: 'KP' },
  { label: 'Korea (South)', languageCode: 'ko-KR', value: 'KR' },
  { label: 'Kuwait', languageCode: 'ar-KW', value: 'KW' },
  { label: 'Kyrgyzstan', languageCode: 'ky-KG', value: 'KG' },
  { label: 'Lao People"S Democratic Republic', languageCode: 'lo-LA', value: 'LA' },
  { label: 'Latvia', languageCode: 'lv-LV', value: 'LV' },
  { label: 'Lebanon', languageCode: 'ar-LB', value: 'LB' },
  { label: 'Lesotho', languageCode: 'en-LS', value: 'LS' },
  { label: 'Liberia', languageCode: 'en-LR', value: 'LR' },
  { label: 'Libyan Arab Jamahiriya', languageCode: 'ar-LY', value: 'LY' },
  { label: 'Liechtenstein', languageCode: 'de-LI', value: 'LI' },
  { label: 'Lithuania', languageCode: 'lt-LT', value: 'LT' },
  { label: 'Luxembourg', languageCode: 'fr-LU', value: 'LU' },
  { label: 'Macao', languageCode: 'zh-MO', value: 'MO' },
  { label: 'Macedonia', languageCode: 'mk-MK', value: 'MK' },
  { label: 'Madagascar', languageCode: 'mg-MG', value: 'MG' },
  { label: 'Malawi', languageCode: 'en-MW', value: 'MW' },
  { label: 'Malaysia', languageCode: 'ms-MY', value: 'MY' },
  { label: 'Maldives', languageCode: 'dv-MV', value: 'MV' },
  { label: 'Mali', languageCode: 'bm-ML', value: 'ML' },
  { label: 'Malta', languageCode: 'mt-MT', value: 'MT' },
  { label: 'Marshall Islands', languageCode: 'en-MH', value: 'MH' },
  { label: 'Martinique', languageCode: 'fr-MQ', value: 'MQ' },
  { label: 'Mauritania', languageCode: 'ar-MR', value: 'MR' },
  { label: 'Mauritius', languageCode: 'en-MU', value: 'MU' },
  { label: 'Mayotte', languageCode: 'fr-YT', value: 'YT' },
  { label: 'Mexico', languageCode: 'es-MX', value: 'MX' },
  { label: 'Micronesia', languageCode: 'en-FM', value: 'FM' },
  { label: 'Moldova', languageCode: 'ro-MD', value: 'MD' },
  { label: 'Monaco', languageCode: 'fr-MC', value: 'MC' },
  { label: 'Mongolia', languageCode: 'mn-MN', value: 'MN' },
  { label: 'Montenegro', languageCode: 'sr-ME', value: 'ME' },
  { label: 'Montserrat', languageCode: 'en-MS', value: 'MS' },
  { label: 'Morocco', languageCode: 'ar-MA', value: 'MA' },
  { label: 'Mozambique', languageCode: 'pt-MZ', value: 'MZ' },
  { label: 'Myanmar', languageCode: 'my-MM', value: 'MM' },
  { label: 'Namibia', languageCode: 'en-NA', value: 'NA' },
  { label: 'Nauru', languageCode: 'en-NR', value: 'NR' },
  { label: 'Nepal', languageCode: 'ne-NP', value: 'NP' },
  { label: 'Netherlands', languageCode: 'nl-NL', value: 'NL' },
  { label: 'Netherlands Antilles', languageCode: 'nl-AN', value: 'AN' },
  { label: 'New Caledonia', languageCode: 'fr-NC', value: 'NC' },
  { label: 'New Zealand', languageCode: 'en-NZ', value: 'NZ' },
  { label: 'Nicaragua', languageCode: 'es-NI', value: 'NI' },
  { label: 'Niger', languageCode: 'fr-NE', value: 'NE' },
  { label: 'Nigeria', languageCode: 'en-NG', value: 'NG' },
  { label: 'Niue', languageCode: 'en-NU', value: 'NU' },
  { label: 'Norfolk Island', languageCode: 'en-NF', value: 'NF' },
  { label: 'Northern Mariana Islands', languageCode: 'en-MP', value: 'MP' },
  { label: 'Norway', languageCode: 'no-NO', value: 'NO' },
  { label: 'Oman', languageCode: 'ar-OM', value: 'OM' },
  { label: 'Pakistan', languageCode: 'ur-PK', value: 'PK' },
  { label: 'Palau', languageCode: 'en-PW', value: 'PW' },
  { label: 'Palestinian Territory', languageCode: 'ar-PS', value: 'PS' },
  { label: 'Panama', languageCode: 'es-PA', value: 'PA' },
  { label: 'Papua New Guinea', languageCode: 'en-PG', value: 'PG' },
  { label: 'Paraguay', languageCode: 'es-PY', value: 'PY' },
  { label: 'Peru', languageCode: 'es-PE', value: 'PE' },
  { label: 'Philippines', languageCode: 'fil-PH', value: 'PH' },
  { label: 'Pitcairn', languageCode: 'en-PN', value: 'PN' },
  { label: 'Poland', languageCode: 'pl-PL', value: 'PL' },
  { label: 'Portugal', languageCode: 'pt-PT', value: 'PT' },
  { label: 'Puerto Rico', languageCode: 'es-PR', value: 'PR' },
  { label: 'Qatar', languageCode: 'ar-QA', value: 'QA' },
  { label: 'Réunion', languageCode: 'fr-RE', value: 'RE' },
  { label: 'Romania', languageCode: 'ro-RO', value: 'RO' },
  { label: 'Russian Federation', languageCode: 'ru-RU', value: 'RU' },
  { label: 'Rwanda', languageCode: 'rw-RW', value: 'RW' },
  { label: 'Saint Barthélemy', languageCode: 'fr-BL', value: 'BL' },
  { label: 'Saint Helena', languageCode: 'en-SH', value: 'SH' },
  { label: 'Saint Kitts and Nevis', languageCode: 'en-KN', value: 'KN' },
  { label: 'Saint Lucia', languageCode: 'en-LC', value: 'LC' },
  { label: 'Saint Martin', languageCode: 'fr-MF', value: 'MF' },
  { label: 'Saint Pierre and Miquelon', languageCode: 'fr-PM', value: 'PM' },
  { label: 'Saint Vincent and Grenadines', languageCode: 'en-VC', value: 'VC' },
  { label: 'Samoa', languageCode: 'en-WS', value: 'WS' },
  { label: 'San Marino', languageCode: 'it-SM', value: 'SM' },
  { label: 'Sao Tome and Principe', languageCode: 'pt-ST', value: 'ST' },
  { label: 'Saudi Arabia', languageCode: 'ar-SA', value: 'SA' },
  { label: 'Senegal', languageCode: 'fr-SN', value: 'SN' },
  { label: 'Serbia', languageCode: 'sr-RS', value: 'RS' },
  { label: 'Seychelles', languageCode: 'fr-SC', value: 'SC' },
  { label: 'Sierra Leone', languageCode: 'en-SL', value: 'SL' },
  { label: 'Singapore', languageCode: 'en-SG', value: 'SG' },
  { label: 'Slovakia', languageCode: 'sk-SK', value: 'SK' },
  { label: 'Slovenia', languageCode: 'sl-SI', value: 'SI' },
  { label: 'Solomon Islands', languageCode: 'en-SB', value: 'SB' },
  { label: 'Somalia', languageCode: 'so-SO', value: 'SO' },
  { label: 'South Africa', languageCode: 'en-ZA', value: 'ZA' },
  { label: 'South Georgia and the South Sandwich Islands', languageCode: 'en-001', value: 'GS' },
  { label: 'Spain', languageCode: 'es-ES', value: 'ES' },
  { label: 'Sri Lanka', languageCode: 'si-LK', value: 'LK' },
  { label: 'Sudan', languageCode: 'ar-SD', value: 'SD' },
  { label: 'Suriname', languageCode: 'nl-SR', value: 'SR' },
  { label: 'Svalbard and Jan Mayen', languageCode: 'no-SJ', value: 'SJ' },
  { label: 'Swaziland', languageCode: 'en-SZ', value: 'SZ' },
  { label: 'Sweden', languageCode: 'sv-SE', value: 'SE' },
  { label: 'Switzerland', languageCode: 'de-CH', value: 'CH' },
  { label: 'Syrian Arab Republic', languageCode: 'ar-SY', value: 'SY' },
  { label: 'Taiwan', languageCode: 'zh-TW', value: 'TW' },
  { label: 'Tajikistan', languageCode: 'tg-TJ', value: 'TJ' },
  { label: 'Tanzania', languageCode: 'sw-TZ', value: 'TZ' },
  { label: 'Thailand', languageCode: 'th-TH', value: 'TH' },
  { label: 'Timor-Leste', languageCode: 'pt-TL', value: 'TL' },
  { label: 'Togo', languageCode: 'fr-TG', value: 'TG' },
  { label: 'Tokelau', languageCode: 'en-TK', value: 'TK' },
  { label: 'Tonga', languageCode: 'to-TO', value: 'TO' },
  { label: 'Trinidad and Tobago', languageCode: 'en-TT', value: 'TT' },
  { label: 'Tunisia', languageCode: 'ar-TN', value: 'TN' },
  { label: 'Turkey', languageCode: 'tr-TR', value: 'TR' },
  { label: 'Turkmenistan', languageCode: 'tk-TM', value: 'TM' },
  { label: 'Turks and Caicos Islands', languageCode: 'en-TC', value: 'TC' },
  { label: 'Tuvalu', languageCode: 'en-TV', value: 'TV' },
  { label: 'Uganda', languageCode: 'sw-UG', value: 'UG' },
  { label: 'Ukraine', languageCode: 'uk-UA', value: 'UA' },
  { label: 'United Arab Emirates', languageCode: 'ar-AE', value: 'AE' },
  { label: 'United Kingdom', languageCode: 'en-GB', value: 'GB' },
  { label: 'United States', languageCode: 'en-US', value: 'US' },
  { label: 'United States Minor Outlying Islands', languageCode: 'en-001', value: 'UM' },
  { label: 'Uruguay', languageCode: 'es-UY', value: 'UY' },
  { label: 'Uzbekistan', languageCode: 'uz-UZ', value: 'UZ' },
  { label: 'Vanuatu', languageCode: 'bi-VU', value: 'VU' },
  { label: 'Venezuela', languageCode: 'es-VE', value: 'VE' },
  { label: 'Viet Nam', languageCode: 'vi-VN', value: 'VN' },
  { label: 'Virgin Islands, British', languageCode: 'en-VG', value: 'VG' },
  { label: 'Virgin Islands, U.S.', languageCode: 'en-VI', value: 'VI' },
  { label: 'Wallis and Futuna', languageCode: 'fr-WF', value: 'WF' },
  { label: 'Western Sahara', languageCode: 'ar-EH', value: 'EH' },
  { label: 'Yemen', languageCode: 'ar-YE', value: 'YE' },
  { label: 'Zambia', languageCode: 'en-ZM', value: 'ZM' },
  { label: 'Zimbabwe', languageCode: 'en-ZW', value: 'ZW' },
];
