import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import buildPath from 'utils/build_path';
import routes from 'routing/routes';
import getUrlParams from 'utils/get_url_params';

import Loading from 'components/loading';
import PageTitle from 'components/page_title/page_title';
import GiftCardBookingConfirmation from 'components/gift_card_store_booking_confirmation/gift_card_store_booking_confirmation';

import { PropertyGiftCardsActionsContext, PropertyGiftCardsDataContext } from 'containers/data_context';

import styles from './gift_card_store_booking_confirmation_page.module.scss';

const GiftCardStoreBookingConfirmationPage: React.FC = () => {
  const { t } = useTranslation();

  const { giftCardNumber, selectedProperty } = useParams<{
    selectedProperty: string;
    giftCardNumber: string;
  }>();
  const { entry } = getUrlParams() as { entry: string };

  const { getGiftCardBookingDetails, getGiftCardDetails } = useContext(PropertyGiftCardsActionsContext);
  const [successful, setSuccessful] = useState(false);

  const {
    giftCardBookingDetails: { data: bookingDetails, isLoading: isLoadingBookingDetails },
    giftCardDetails: { data: giftCard, isLoading: isLoadingGiftCard },
  } = useContext(PropertyGiftCardsDataContext);

  useEffect(() => {
    const fetchData = async () => {
      if (!successful) {
        try {
          const data = await getGiftCardBookingDetails({ storeSlug: selectedProperty, giftCardNumber, entry });

          if (data) {
            setSuccessful(true);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    const intervalId = setInterval(fetchData, 3000);

    return () => clearInterval(intervalId);
  }, [successful, getGiftCardBookingDetails]);

  useEffect(() => {
    if (bookingDetails?.giftCardNumber) {
      getGiftCardDetails({ storeSlug: selectedProperty, giftCardId: bookingDetails.giftCardNumber });
    }
  }, [bookingDetails]);

  if (isLoadingBookingDetails || isLoadingGiftCard) {
    return (
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    );
  }

  const handleBuildPath = (path: string) => buildPath('', path, { selectedProperty });

  return (
    <div className={styles.root}>
      {bookingDetails && (
        <PageTitle
          steps={[
            { title: t('general.home'), link: handleBuildPath(routes.giftCardStorePage) },
            { title: t('gift_card'), link: '' },
            { title: giftCard?.title, link: '' },
            { title: t('general.confirmation'), link: '' },
          ]}
        />
      )}

      {bookingDetails ? (
        <GiftCardBookingConfirmation bookingDetails={bookingDetails} giftCard={giftCard} />
      ) : (
        <Loading size={48} />
      )}
    </div>
  );
};

export default GiftCardStoreBookingConfirmationPage;
