import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, notification, List, DatePicker, Typography, Input } from 'antd';
import { PaginationConfig } from 'antd/lib/pagination';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import formatCurrencyPrice from 'utils/format_currency_price';
import { IGiftCardDetailsFormProps } from './gift_card_details_form.types';
import styles from './gift_card_details_form.module.scss';

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
};

const GiftCardDetailsForm: React.FC<IGiftCardDetailsFormProps> = ({ giftCard, property, handleUpdateGiftCard }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { expirationDate, sendByLetter, ...giftCardValues } = giftCard;

  const requiredRules = [{ required: true, message: t('validation_messages.required') }];

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [visibleItems, setVisibleItems] = useState(false);

  const handleSubmit = async (): Promise<void> => {
    try {
      const formValues = form.getFieldsValue(true);

      setSubmitLoading(true);
      await handleUpdateGiftCard({
        expirationDate: formValues.expirationDate.toISOString(),
        notes: formValues.notes,
        name: formValues.name,
        email: formValues.email,
      });
      openNotificationWithIcon('success');
    } catch (e) {
      console.log(e);
      openNotificationWithIcon('error');
    } finally {
      setSubmitLoading(false);
    }
  };

  const openNotificationWithIcon = (type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: t('gift_card.updated_notification'),
      });
    } else {
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
  };

  const propertyListPagination = (visibleItems: boolean): false | PaginationConfig => {
    return !visibleItems ? false : { pageSize: 5, hideOnSinglePage: true };
  };

  const propertyItemRender = (item: string, visibleItems: boolean): React.ReactNode => {
    if (visibleItems) {
      return <List.Item>{item}</List.Item>;
    } else {
      return null;
    }
  };

  const redeemedInfo = useMemo(() => {
    if (!giftCard.redeemedBy || !giftCard.redeemMethod) {
      return t('gift_card.booked_true');
    }
    return `${t('gift_card.booked_true')} - ${giftCard.redeemMethod} - ${giftCard.redeemedBy}`;
  }, [giftCard]);

  return (
    <div data-testid="GiftCardDetailsForm" className={styles.root}>
      <Form
        name="GiftCardDetailsForm"
        onFinish={handleSubmit}
        form={form}
        className={styles.detailsForm}
        initialValues={{ ...giftCardValues, expirationDate: dayjs(expirationDate) }}
        {...formItemLayout}
      >
        <Form.Item name="title" label={t('gift_card.title')}>
          <Typography.Text> {giftCard.title} </Typography.Text>
        </Form.Item>

        <Form.Item name="generatedId" label={t('gift_card.number')}>
          <Typography.Text> {giftCard.generatedId} </Typography.Text>
        </Form.Item>
        <Form.Item name="expirationDate" rules={[...requiredRules]} label={t('general.expiration_date')}>
          <DatePicker disabledDate={current => current?.isBefore(dayjs().startOf('day'))} />
        </Form.Item>

        <Form.Item name="booked" label={t('gift_card.booked_status')}>
          <Typography.Text>{giftCard.booked ? redeemedInfo : t('gift_card.booked_false')} </Typography.Text>
        </Form.Item>

        <Form.Item name="sendByletter" label={t('gift_card.send_by_letter')}>
          <Typography.Text> {giftCard.sendByLetter ? t('general.yes') : t('general.no')} </Typography.Text>
        </Form.Item>

        <Form.Item name="delivered" label={t('gift_card.delivered')}>
          <Typography.Text> {giftCard.delivered ? t('general.yes') : t('general.no')} </Typography.Text>
        </Form.Item>

        <Form.Item name="name" rules={requiredRules} label={t('gift_card.name')}>
          <Input placeholder={t('gift_card.name')} />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[...requiredRules, { type: 'email', message: t('general.please_enter_valid_email') }]}
          label={t('gift_card.email')}
        >
          <Input placeholder={t('gift_card.email')} />
        </Form.Item>

        <Form.Item name="netPrice" label={t('gift_card.price')}>
          <Typography.Text>
            {formatCurrencyPrice(giftCard.netPrice, property?.currency, property?.country)}
          </Typography.Text>
        </Form.Item>

        <Form.Item name="buyerName" label={t('gift_card.customer_name')}>
          <Typography.Text> {giftCard.buyerName} </Typography.Text>
        </Form.Item>

        <Form.Item name="buyerEmail" label={t('gift_card.customer_email')}>
          <Typography.Text> {giftCard.buyerEmail} </Typography.Text>
        </Form.Item>

        <Form.Item name="propertyList" label={t('gift_card.eligible_at')}>
          <List
            size="small"
            header={
              <div className="clearfix">
                <span>{`${giftCard.propertyList.length}x ${
                  giftCard.propertyList.length > 1 ? t('gift_card.properties') : t('gift_card.property')
                }`}</span>
                <Button className={styles.visible_button} onClick={() => setVisibleItems(!visibleItems)}>
                  {visibleItems ? <UpOutlined /> : <DownOutlined />}
                </Button>
              </div>
            }
            bordered
            pagination={propertyListPagination(visibleItems)}
            dataSource={giftCard.propertyList}
            renderItem={item => propertyItemRender(item, visibleItems)}
          />
        </Form.Item>

        <Form.Item name="notes" label={t('gift_card.notes')}>
          <Input.TextArea rows={4} />
        </Form.Item>

        {sendByLetter && (
          <>
            <legend>{'Delivery Details'}</legend>

            <Form.Item name="deliveryName" label={t('general.name')}>
              <Typography.Text> {giftCard.deliveryName} </Typography.Text>
            </Form.Item>

            <Form.Item name="deliveryAddress" label={t('general.address')}>
              <Typography.Text> {giftCard.deliveryAddress} </Typography.Text>
            </Form.Item>

            <Form.Item name="deliveryZipCode" label={t('general.zip_code')}>
              <Typography.Text> {giftCard.deliveryZipCode} </Typography.Text>
            </Form.Item>

            <Form.Item name="deliveryCity" label={t('general.city')}>
              <Typography.Text> {giftCard.deliveryCity} </Typography.Text>
            </Form.Item>

            <Form.Item name="deliveryState" label={t('general.state')}>
              <Typography.Text> {giftCard.deliveryState} </Typography.Text>
            </Form.Item>

            <Form.Item name="deliveryCountry" label={t('general.country')}>
              <Typography.Text> {giftCard.deliveryCountry} </Typography.Text>
            </Form.Item>
          </>
        )}

        <div className={styles.submit_section}>
          <Button
            loading={submitLoading}
            className={styles.submit_button}
            type="primary"
            htmlType="submit"
            size="large"
          >
            {t('gift_card.update_type')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default GiftCardDetailsForm;
