import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

import buildPath from 'utils/build_path';
import routes from 'routing/routes';
import dateFormatter from 'utils/date_formatter';

import { PropertyGiftCardsActionsContext, PropertyGiftCardsDataContext } from 'containers/data_context';

import GiftCardStoreBookingCheckout from 'components/gift_card_store_booking_checkout';
import {
  IGiftCardStoreBookingCheckoutForm,
  IPhone,
} from 'components/gift_card_store_booking_checkout/gift_card_store_booking_checkout.types';
import PageTitle from 'components/page_title/page_title';

import styles from './gift_card_store_booking_checkout_page.module.scss';

const GiftCardStoreBookingCheckoutPage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const { setGiftCardCheckoutData } = useContext(PropertyGiftCardsActionsContext);

  const { selectedProperty, giftCardNumber } = useParams<{ selectedProperty: string; giftCardNumber: string }>();
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);

  const propertyGiftCardsActions = useContext(PropertyGiftCardsActionsContext);
  const propertyGiftCardsData = useContext(PropertyGiftCardsDataContext);

  const { setParams, clearDataFromStorage, saveDataToStorage, getDataFromStorage } = propertyGiftCardsActions;

  const storageData = getDataFromStorage();

  const {
    params,
    propertyInfo: { data: propertyDetails },
    giftCardBooking: { isLoading: isSavingBooking },
  } = propertyGiftCardsData;

  const { checkinDate, checkoutDate, propertyUnit, ratePlan, isUpsell, isMarketingCampaign } = storageData
    ? storageData
    : params;

  useEffect(() => {
    propertyGiftCardsActions.getPropertyInfo({ propertyId: selectedProperty });
  }, []);

  const openNotificationWithIcon = (type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: 'Booking confirmed.',
      });
    } else {
      onErrorRedirect();
      return notification['error']({
        message: 'An error occurred',
        description: 'Sorry, booking could not be completed.',
      });
    }
  };

  const onErrorRedirect = () => {
    clearDataFromStorage();
    const redirectPath = buildPath('', routes.giftCardStorePage, { selectedProperty });

    history.push(redirectPath);
  };

  const formatPhoneNumber = (phone: IPhone | undefined): string => {
    if (phone === undefined) {
      return '';
    }

    return `${phone.areaCode}${phone.phoneNumber}`;
  };

  const formatChannexPhoneNumber = (phone: IPhone): string => {
    return `+${phone.countryCode}${phone.areaCode}${phone.phoneNumber}`;
  };

  const handleSave = async (values: IGiftCardStoreBookingCheckoutForm): Promise<void> => {
    const giftCardBookingParams = {
      generatedId: giftCardNumber,
      name: values.name,
      surname: values.surname,
      dateFrom: dateFormatter.toApi(checkinDate),
      dateTo: dateFormatter.toApi(checkoutDate),
    };

    setParams({ ...params, giftCardBookingParams: { ...giftCardBookingParams, email: values.email } });

    const booking = {
      giftCardGeneratedId: giftCardNumber,
      hotelName: propertyDetails.title,
      status: 'new',
      currency: 'ISK',
      arrivalDate: dateFormatter.toApi(checkinDate),
      departureDate: dateFormatter.toApi(checkoutDate),
      customer: {
        name: values.name,
        surname: values.surname,
        mail: values.email,
        nationality: values.nationality,
        phone: formatChannexPhoneNumber(values.phone),
      },
      propertyId: selectedProperty,
      propertyName: values.propertyName,
      notes: values.specialRequest ? `Special Request: ${values.specialRequest}\n` : '',
      rooms: [
        {
          ratePlanCode: ratePlan.id,
          occupancy: {
            adults: ratePlan.occupancy.adults || 0,
            children: ratePlan.occupancy.children || 0,
            infants: ratePlan.occupancy.infants || 0,
          },
          roomTypeCode: propertyUnit.id,
          guests: [{ name: values.name, surname: values.surname }],
        },
      ],
    };
    if (isUpsell) {
      setIsLoadingCheckout(true);

      const bookerDetails = {
        firstName: values.useBookerPaymentDetails ? values.name : values.payerFirstName,
        lastName: values.useBookerPaymentDetails ? values.surname : values.payerLastName,
        address: values.useBookerPaymentDetails ? values.address : values.payerOptionalAddres,
        phone: values.useBookerPaymentDetails
          ? formatPhoneNumber(values.phone)
          : formatPhoneNumber(values?.payerOptionalPhone),
        phoneCountryCode: values.useBookerPaymentDetails
          ? values.phone.countryCode
          : values.payerOptionalPhone?.countryCode,
        email: values.useBookerPaymentDetails ? values.email : values.payerEmail,
      };

      const giftCardParams = {
        product_array: [{ booking: booking, ratePlanId: ratePlan.parentRatePlanId }],
        upsell: isUpsell,
        locale: i18n.language,
        ...bookerDetails,
      };

      const savedGiftCardsParams = getDataFromStorage();
      saveDataToStorage({ ...savedGiftCardsParams, bookerDetails: values });
      try {
        const response = await setGiftCardCheckoutData({ giftCardParams, propertyId: selectedProperty });
        if (response?.redirectUrl) {
          window.location = response?.redirectUrl;
        } else {
          const { formActionUrl, ...rest } = response;

          const params = new URLSearchParams(rest);
          window.location.href = formActionUrl + '?' + params;
          return;
        }
      } catch (e) {
        return notification['error']({
          message: 'Something went wrong. Please try again.',
        });
      }
      setIsLoadingCheckout(false);
    } else {
      try {
        const createdBooking = await propertyGiftCardsActions.createGiftCardBooking(booking);
        if (createdBooking?.uniqueId) {
          const queryParams = isMarketingCampaign ? `?locale=en&entry=${createdBooking.uniqueId}` : '';
          const redirectPath = buildPath(queryParams, routes.giftCardStoreBookingConfirmationPage, {
            selectedProperty,
            giftCardNumber,
          });
          history.push(redirectPath);
        }

        openNotificationWithIcon('success');
      } catch (e) {
        openNotificationWithIcon('error');
      }
    }
  };

  const handleBuildPath = (path: string) => buildPath('', path, { selectedProperty });

  return (
    <div className={styles.root} data-testid="GiftCardStoreBookingCheckoutPage">
      <PageTitle
        steps={[
          { title: t('general.home'), link: handleBuildPath(routes.giftCardStorePage) },
          { title: t('gift_card'), link: '' },
          { title: propertyUnit?.title, link: '' },
        ]}
      />
      <GiftCardStoreBookingCheckout
        propertyUnit={propertyUnit}
        property={propertyDetails}
        onSave={handleSave}
        checkinDate={checkinDate}
        checkoutDate={checkoutDate}
        isSavingBooking={isSavingBooking}
        isLoadingCheckout={isLoadingCheckout}
        isUpsell={isUpsell}
        isMarketingCampaign={isMarketingCampaign}
        {...(storageData?.bookerDetails && { bookerDetails: storageData?.bookerDetails })}
      />
    </div>
  );
};

export default GiftCardStoreBookingCheckoutPage;
