import ApiActions from 'api_actions';

export const SET_PAYMENT_PROVIDERS_LOADING = 'SET_PAYMENT_PROVIDERS_LOADING';
export const SET_PAYMENT_PROVIDERS = 'SET_PAYMENT_PROVIDERS';
export const SET_PAYMENT_PROVIDER_UPDATE_LOADING = 'SET_PAYMENT_PROVIDER_UPDATE_LOADING';
export const SET_PAYMENT_PROVIDER_CREATE_LOADING = 'SET_PAYMENT_PROVIDER_CREATE_LOADING';
export const SET_DELETE_PAYMENT_PROVIDER_LOADING = 'SET_DELETE_PAYMENT_PROVIDER_LOADING';
export const RESET_PAYMENT_PROVIDERS = 'RESET_PAYMENT_PROVIDERS';

const setPaymentProvidersLoading = (dispatch) => {
  return dispatch({ type: SET_PAYMENT_PROVIDERS_LOADING });
};

const loadPaymentProviders = async (dispatch, params) => {
  setPaymentProvidersLoading(dispatch, params);
  try {
    const data = await ApiActions.getPaymentProviders(params);
    setPaymentProviders(dispatch, { data });
  } catch (e) {
    throw e;
  }
};

const setPaymentProviders = (dispatch, payload) => {
  return dispatch({ type: SET_PAYMENT_PROVIDERS, payload });
};

const setCreatePaymentProviderLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_PAYMENT_PROVIDER_CREATE_LOADING, params });
};

const createPaymentProvider = async (dispatch, params) => {
  setCreatePaymentProviderLoading(dispatch);

  try {
    const paymentProvider = await ApiActions.createPaymentProvider(params);
    setCreatePaymentProviderLoading(dispatch, false);
    await loadPaymentProviders(dispatch, params.propertyId);
    return paymentProvider;
  } catch (e) {
    setCreatePaymentProviderLoading(dispatch, false);
    throw e;
  }
};

const setUpdatePaymentProviderLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_PAYMENT_PROVIDER_UPDATE_LOADING, params });
};

const updatePaymentProvider = async (dispatch, params) => {
  setUpdatePaymentProviderLoading(dispatch);
  try {
    const paymentProvider = await ApiActions.updatePaymentProvider(params);
    setUpdatePaymentProviderLoading(dispatch, false);
    await loadPaymentProviders(dispatch, params.propertyId);
    return paymentProvider;
  } catch (e) {
    setUpdatePaymentProviderLoading(dispatch, false);
    throw e;
  }
};

const setDeletePaymentProviderLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_DELETE_PAYMENT_PROVIDER_LOADING, params });
};

const deletePaymentProvider = async (dispatch, params) => {
  setDeletePaymentProviderLoading(dispatch);

  try {
    await ApiActions.deletePaymentProvider(params);
    setDeletePaymentProviderLoading(dispatch, false);
    await loadPaymentProviders(dispatch, params.propertyId);
  } catch (e) {
    setDeletePaymentProviderLoading(dispatch, false);
    throw e;
  }
};

const resetPaymentProviders = (dispatch) => {
  return dispatch({ type: RESET_PAYMENT_PROVIDERS });
}

export const actions = {
  loadPaymentProviders,
  createPaymentProvider,
  updatePaymentProvider,
  deletePaymentProvider,
  resetPaymentProviders,
};
