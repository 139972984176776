// Generated with util/create-component.js
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';
import dayjs from 'dayjs';

import DATE_FORMATTER from 'utils/date_formatter';
import formatCurrencyPrice from 'utils/format_currency_price';

import SearchInput from 'components/search_input';

import { IArchivedGiftCardsProps, IArchivedGiftCard } from './archived_gift_cards.types';
import styles from './archived_gift_cards.module.scss';

const COLUMN_WIDTH = 150;

const ArchivedGiftCards: React.FC<IArchivedGiftCardsProps> = ({ giftCards, property }) => {
  const { t } = useTranslation();

  const [archivedList, setArchivedList] = useState<IArchivedGiftCard[]>(giftCards);

  useEffect(() => {
    setArchivedList(giftCards);
  }, [giftCards]);

  const tableColumns: ColumnsType<IArchivedGiftCard> = [
    {
      title: t('general.title'),
      dataIndex: 'title',
      key: 'title',
      width: COLUMN_WIDTH,
    },
    {
      title: t('bookings_list.price_title'),
      dataIndex: 'price',
      key: 'price',
      render: (record: IArchivedGiftCard['price']) => (
        <div>{formatCurrencyPrice(record, property?.currency, property?.country)}</div>
      ),
      width: COLUMN_WIDTH,
    },
    {
      title: t('gift_card.validity_label'),
      dataIndex: 'expirationDuration',
      key: 'expirationDuration',
      render: (expirationDuration: IArchivedGiftCard['expirationDuration']) => (
        <div>
          {expirationDuration} {expirationDuration == 1 ? t('general.month') : t('general.months')}
        </div>
      ),
      width: COLUMN_WIDTH,
    },
    {
      title: t('link.inventory'),
      dataIndex: 'inventory',
      key: 'inventory',
      width: COLUMN_WIDTH,
    },
    {
      title: t('general.created_at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: IArchivedGiftCard['createdAt']) => <div>{DATE_FORMATTER.toUiDateTime(createdAt)}</div>,
      sorter: (b1: IArchivedGiftCard, b2: IArchivedGiftCard) => dayjs(b1.createdAt).diff(dayjs(b2.createdAt), 'second'),
      defaultSortOrder: 'descend',
      width: COLUMN_WIDTH,
    },
    {
      title: t('general.updated_at'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: IArchivedGiftCard['updatedAt']) => <div>{DATE_FORMATTER.toUiDateTime(updatedAt)}</div>,
      sorter: (b1: IArchivedGiftCard, b2: IArchivedGiftCard) => dayjs(b1.updatedAt).diff(dayjs(b2.updatedAt), 'second'),
      width: COLUMN_WIDTH,
    },
  ];

  const handleSearchTermChange = useCallback(
    (term: string) => {
      const archivedFiltered = giftCards.filter((giftCard: IArchivedGiftCard) =>
        giftCard.title.toLowerCase().includes(term.toLowerCase()),
      );
      setArchivedList(archivedFiltered);
    },
    [giftCards],
  );

  return (
    <div data-testid="ArchivedGiftCards" className={styles.root}>
      <SearchInput
        onSearch={handleSearchTermChange}
        placeholder={t('general.search_for_a_title')}
        className={styles.search_input}
      />
      <Table
        dataSource={archivedList}
        columns={tableColumns}
        rowKey="id"
        pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
        data-testid="ArchivedGiftCardsList"
        scroll={{ x: 'max-content', y: window.innerHeight - (window.innerWidth < 900 ? 400 : 340) }}
      />
    </div>
  );
};

export default ArchivedGiftCards;
