import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Col, Row, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import USER_ROLE from 'constants/user_role';

import ApiActions from 'api_actions';
import BookingsList from 'components/bookings_list/bookings_list';
import BookingsAdvancedSearch from 'components/bookings_advanced_search/bookings_advanced_search';
import { IBooking, ICancelParams } from 'components/bookings_list/bookings_list.types';
import { IBookingFilters } from 'components/bookings_advanced_search/bookings_advanced_search.types';

import {
  BookingsActionsContext,
  BookingsDataContext,
  PropertySettingsDataContext,
  AuthenticationDataContext,
  StripeActionsContext,
  StripeDataContext,
} from 'containers/data_context';

import styles from './bookings_page.module.scss';
import stripeSubscription from 'utils/stripe_subscription';
import notification from 'utils/notification/notification';

const DEFAULT_PAGINATION = {
  page: 1,
  limit: 10,
};

const DEFAULT_ORDER = {
  orderBy: 'created_at',
  orderDirection: 'desc',
};

const DEFAULT_FILTERS: IBookingFilters = {
  reseller: [],
  pagination: DEFAULT_PAGINATION,
  ...DEFAULT_ORDER,
};

const BookingsPage: React.FC = () => {
  const { t } = useTranslation();
  const {
    loadBookingsList,
    cancelBooking,
    updateBookingGuests,
    updateBookingSpecialRequest,
    deleteBookingUnits,
    loadSuppliersList: loadAllSuppliers,
    loadBookersList,
    loadResellersList,
  } = useContext(BookingsActionsContext);
  const {
    bookings: { data, isLoading },
    suppliers: { data: suppliersList },
    bookers: { data: bookersList },
    resellers: { data: resellersList },
  } = useContext(BookingsDataContext);
  const { user, userRole } = useContext(AuthenticationDataContext);
  const { selectedProperty } = useContext(PropertySettingsDataContext);
  const { checkSupplierPaymentSource } = useContext(StripeActionsContext);
  const { stripeNotification } = useContext(StripeDataContext);

  const [allFilters, setFilters] = useState(DEFAULT_FILTERS);
  const [showStripeBillingAlert, setShowStripeBillingAlert] = useState(false);

  const isReseller = useMemo(() => userRole === USER_ROLE.RESELLER, [userRole]);
  const isSupplier = useMemo(() => userRole === USER_ROLE.SUPPLIER, [userRole]);
  const stripeWarning = useMemo(() => {
    if (isReseller) return stripeSubscription.checkWarningReseller(user);

    return stripeSubscription.checkWarningSupplier(user, selectedProperty);
  }, [user, selectedProperty]);

  useEffect(() => {
    if (isReseller) {
      if (checkStripeSubscription()) return;

      loadAllSuppliers();
      loadBookersList();
    }
  }, []);

  useEffect(() => {
    handleLoadBookingsList();
  }, [allFilters]);

  useEffect(() => {
    setFilters({ ...allFilters, pagination: DEFAULT_PAGINATION });
    if (selectedProperty) {
      if (stripeWarning)
        notification.withIcon('warning', t('general.stripe_warning'), t('general.stripe_warning_description'));

      loadResellersList(selectedProperty);
    }
  }, [selectedProperty]);

  useEffect(() => {
    if (isReseller) return;
    if (!selectedProperty) return;

    checkSupplierPaymentSource(selectedProperty);
    if (stripeNotification)
      notification.withIcon('warning', t('general.stripe_notification'), t('general.stripe_notification_description'));
  }, [stripeNotification, selectedProperty]);

  const handleLoadBookingsList = () => {
    if (checkStripeSubscription()) return;

    if (isSupplier && selectedProperty) {
      loadBookingsList({ ...allFilters, propertyId: selectedProperty });
    } else if (isReseller) {
      loadBookingsList({ ...allFilters });
    }
  };

  const checkStripeSubscription = () => {
    let stripeSubscriptionInvalid;

    if (isReseller) {
      stripeSubscriptionInvalid = stripeSubscription.checkLockReseller(user);
    } else {
      stripeSubscriptionInvalid = stripeSubscription.checkLockSupplier(user, selectedProperty);
    }
    setShowStripeBillingAlert(stripeSubscriptionInvalid);

    return stripeSubscriptionInvalid;
  };

  const handleFilterChange = async (filterName: string, filterValue: string | number | boolean | null | string[]) => {
    if (!filterValue) {
      const filters = JSON.parse(JSON.stringify(allFilters));
      delete filters[filterName];

      setFilters({ ...filters, pagination: DEFAULT_PAGINATION });
    } else {
      setFilters({ ...allFilters, [filterName]: filterValue, pagination: DEFAULT_PAGINATION });
    }
  };

  const handleSearch = async (filters: IBookingFilters) => {
    setFilters({ ...filters, pagination: DEFAULT_PAGINATION });
  };

  const handleChange = useCallback(
    (
      currentPagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<IBooking> | SorterResult<IBooking>[],
    ) => {
      let appliedFilters = JSON.parse(JSON.stringify(allFilters));

      Object.keys(filters).map((filterName: string) => {
        if (!filters[filterName] && filterName !== 'reseller') {
          delete appliedFilters[filterName];
        } else if (filterName === 'reseller') {
          if (allFilters[filterName]?.length) {
            appliedFilters[filterName] = allFilters[filterName];
          } else {
            delete appliedFilters[filterName];
          }
        } else {
          appliedFilters[filterName] = filters[filterName]?.[0];
        }
      });

      if (!(sorter as SorterResult<IBooking>)?.order) {
        appliedFilters = { ...appliedFilters, ...DEFAULT_ORDER };
      } else {
        appliedFilters.orderBy = String((sorter as SorterResult<IBooking>)?.field)
          .replace(/[A-Z]/g, '_' + '$&')
          .toLowerCase();
        appliedFilters.orderDirection = (sorter as SorterResult<IBooking>).order === 'ascend' ? 'asc' : 'desc';
      }

      const { current, pageSize } = currentPagination;

      appliedFilters.pagination = {
        page: allFilters.pagination?.page !== current ? current : 1,
        limit: pageSize || 10,
      };

      setFilters(appliedFilters);
    },
    [allFilters],
  );

  const handleCancelBooking = (params: ICancelParams) => cancelBooking(params);

  const stripeCustomerPortalLink = async () => {
    let response;

    try {
      if (isReseller) {
        response = await ApiActions.getResellerStripeCustomerPortalUrl();
      } else {
        response = await ApiActions.getSupplierStripeCustomerPortalUrl(selectedProperty);
      }
    } catch {
      notification.withIcon('error', t('general.stripe_cutomer_portal_error_message'));
    }

    window.open(response.stripeCustomerPortalSessionUrl, '_blank');
  };

  const paginationData = {
    current: allFilters.pagination?.page,
    total: data?.meta?.total,
    pageSize: allFilters.pagination?.limit,
    hideOnSinglePage: true,
    showSizeChanger: false,
  };

  return (
    <div className={styles.root} data-testid="BookingsPage">
      {showStripeBillingAlert && (
        <div>
          <Alert
            message={t('general.stripe_locked')}
            showIcon
            description={t('general.stripe_locked_description')}
            type="error"
            action={
              <Button size="small" danger onClick={stripeCustomerPortalLink}>
                {t('general.stripe_subscription_details')}
              </Button>
            }
          />
        </div>
      )}
      <div className={styles.tableContainer}>
        <div className={styles.bookingsListTable}>
          <Row justify="start" align="middle">
            <Col span={24}>
              <BookingsAdvancedSearch
                filters={allFilters}
                onSearch={handleSearch}
                isReseller={isReseller}
                suppliers={suppliersList?.data?.data}
                bookers={bookersList?.data}
                resellers={resellersList?.data}
              />
            </Col>
          </Row>
          <BookingsList
            bookings={data?.data}
            isLoading={isLoading}
            resellerEmail={user.email}
            isReseller={isReseller}
            pagination={paginationData}
            onChange={handleChange}
            onCancelBooking={handleCancelBooking}
            onFilterChange={handleFilterChange}
            onUpdateBookingGuests={updateBookingGuests}
            onUpdateBookingSpecialRequest={updateBookingSpecialRequest}
            onDeleteBookingUnits={deleteBookingUnits}
            resellers={resellersList?.data}
            filters={allFilters}
          />
        </div>
      </div>
    </div>
  );
};

export default BookingsPage;
