import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Row, Col, Tooltip } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { MinusOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { encode } from 'js-base64';
import classNames from 'classnames';
import { useMedia } from 'react-media';

import routes from 'routing/routes';
import buildPath from 'utils/build_path';
import formatCurrencyPrice from 'utils/format_currency_price';
import MEDIA_QUERIES from 'constants/media_queries';
import useTrackingCookieConsent from 'hooks/useTrackingCookieConsent';

import styles from './gift_card_store_cart.module.scss';
import { IGiftCardStoreCartProps, ICartItem } from './gift_card_store_cart.types';

const GiftCardStoreCart: React.FC<IGiftCardStoreCartProps> = ({ cartItems, onGetGiftCardTypeSize, property }) => {
  const matchedQueries = useMedia({ queries: MEDIA_QUERIES });
  const isMobile = matchedQueries.xs || matchedQueries.sm || matchedQueries.md;

  const history = useHistory();
  const { t, i18n } = useTranslation();

  const { selectedProperty } = useParams<{ selectedProperty: string }>();
  const [cartItemsList, setCartItemsList] = useState<ICartItem[]>(cartItems);
  const isTrackingAllowed: boolean = useTrackingCookieConsent();

  const total = cartItemsList.map(item => item.item.netPrice * item.quantity).reduce((prev, curr) => prev + curr, 0);

  useEffect(() => {
    setCartItemsList(cartItems);
  }, [cartItems]);

  useEffect(() => {
    const redirectPath = buildPath('', routes.giftCardStoreCartPage, { selectedProperty });
    history.push('/');
    history.replace(redirectPath);
  }, [cartItemsList]);

  const handlePlusQuantity = async (giftCardTypeId: number) => {
    const updatedCartItems = [...cartItemsList];

    const cartItemIndex = cartItemsList.findIndex(data => data.item.id === giftCardTypeId);
    if (cartItemIndex !== -1) {
      const giftCardTypeSize = await onGetGiftCardTypeSize({ giftCardTypeId, propertyId: selectedProperty });

      if (giftCardTypeSize?.size && updatedCartItems[cartItemIndex].quantity < giftCardTypeSize.size) {
        if (updatedCartItems[cartItemIndex].quantity <= 9) {
          updatedCartItems[cartItemIndex].quantity += 1;
          setCartItemsList(updatedCartItems);
          localStorage.setItem(`cart-items-${selectedProperty}`, encode(JSON.stringify({ items: updatedCartItems })));
        }
      }
    }
  };

  const handleMinusQuantity = (itemId: number) => {
    const updatedCartItems = [...cartItemsList];

    const cartItemIndex = cartItemsList.findIndex(data => data.item.id === itemId);
    if (cartItemIndex != -1) {
      if (updatedCartItems[cartItemIndex].quantity >= 2) {
        updatedCartItems[cartItemIndex].quantity -= 1;
        setCartItemsList(updatedCartItems);
        localStorage.setItem(`cart-items-${selectedProperty}`, encode(JSON.stringify({ items: updatedCartItems })));
      } else {
        handleDelete(itemId);
      }
    }
  };

  const handleDelete = (itemId: number) => {
    const updatedCartItems = [...cartItemsList];

    const cartItemIndex = cartItemsList.findIndex(data => data.item.id === itemId);
    const cartItemToBeRemoved = cartItems[cartItemIndex];
    if (cartItemIndex != -1) {
      if (updatedCartItems.length <= 1) {
        localStorage.removeItem(`cart-items-${selectedProperty}`);
        setCartItemsList([]);
      } else {
        updatedCartItems.splice(cartItemIndex, 1);
        localStorage.setItem(`cart-items-${selectedProperty}`, encode(JSON.stringify({ items: updatedCartItems })));
        setCartItemsList(updatedCartItems);
      }
    }
    isTrackingAllowed &&
      window?.gtag('event', 'remove_from_cart', {
        currency: 'ISK',
        value: cartItemToBeRemoved?.item?.netPrice * cartItemToBeRemoved?.quantity,
        items: [
          {
            item_id: itemId,
            item_name: cartItemToBeRemoved?.item?.title,
            item_category: 'Gift Card',
            price: cartItemToBeRemoved?.item?.netPrice,
            discount: cartItemToBeRemoved?.item?.price - cartItemToBeRemoved?.item?.netPrice,
            quantity: cartItemToBeRemoved?.quantity,
          },
        ],
      });
  };

  const handleOpenCheckoutPage = () => {
    const pagePath = buildPath('', routes.giftCardStoreCheckoutPage, { selectedProperty });
    history.push(pagePath);
  };

  const handleOpenGiftCardStore = useCallback(() => {
    const storePath = buildPath(`?locale=${i18n.language}`, routes.giftCardStorePage, { selectedProperty });

    history.push(storePath);
  }, []);

  const renderItems = cartItemsList.map((item, index) => {
    return (
      <Row key={index} className={styles.itemRow} data-testid="Product" gutter={[0, 20]}>
        <Col sm={2} xs={24}>
          {item?.item?.photos?.[0]?.publicUrl && (
            <img
              className={classNames(styles.photo, 'roundedWrapper')}
              src={item?.item?.photos?.[0]?.publicUrl}
              alt="Room"
            />
          )}
        </Col>
        <Col sm={14} xs={24}>
          <div className={classNames(styles.name, 'highlighted')}>{item.item.title}</div>
          <div className={styles.description}>{item.item.description.replace(/(<([^>]+)>)/gi, '')}</div>
        </Col>

        <Col sm={4} xs={12} className={styles.quantityContainer}>
          <div className={styles.quantity}>
            <Button
              type="link"
              htmlType="submit"
              onClick={() => handleMinusQuantity(item.item.id)}
              className={styles.quantityIterator}
              icon={<MinusOutlined />}
            />

            <Input
              className={styles.quantityInput}
              style={{ backgroundColor: 'transparent', borderColor: 'transparent', fontSize: '24px' }}
              size="large"
              placeholder={`${item.quantity}`}
              type="number"
            />
            <Button
              type="link"
              htmlType="submit"
              onClick={() => handlePlusQuantity(item.item.id)}
              className={styles.quantityIterator}
              icon={<PlusOutlined />}
            />
          </div>
        </Col>
        <Col sm={4} xs={12} className={classNames(styles.priceContainer, 'highlighted')}>
          <div className={styles.priceDetails}>
            <div className={styles.price}>
              {formatCurrencyPrice(item.item.netPrice * item.quantity, property?.currency, property?.country)}
            </div>
            {item.item.netPrice !== item.item.price && (
              <div className="discountPrice">
                {formatCurrencyPrice(item.item.price * item.quantity, property?.currency, property?.country)}
              </div>
            )}
          </div>

          <Tooltip title={t('general.delete')}>
            <DeleteOutlined onClick={() => handleDelete(item.item.id)} data-testid="DeleteProduct" />
          </Tooltip>
        </Col>
      </Row>
    );
  });

  if (!cartItemsList?.length) {
    return (
      <div className={styles.emptyCartContainer} data-testid="GiftCardStoreCart">
        <div className={styles.emptyCartTitle}>
          {t('gift_card.basket_is_empty')} <br />
          {t('gift_card.add_to_backet')}
        </div>
        <Button size="large" className="blackButton" onClick={handleOpenGiftCardStore}>
          {t('gift_card.click_here_to_view_all')}
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.root} data-testid="GiftCardStoreCart">
      <div>
        <div className={classNames(styles.header, 'highlighted')}>{t('gift_card.your_cart')}</div>
        {!isMobile && (
          <Row className={classNames(styles.tableHeader, 'roundedWrapper')}>
            <Col sm={14} offset={2}>
              {t('general.products')}
            </Col>
            <Col sm={4} className={styles.quantityLabel}>
              {t('general.quantity')}
            </Col>
            <Col sm={4} className={styles.totalLabel}>
              {t('hotel_page.total')}
            </Col>
          </Row>
        )}
        {renderItems}
        <Row justify="space-between" className={classNames(styles.summary, 'roundedWrapper', 'highlighted')}>
          <Col span={12}>{t('hotel_page.total')}</Col>
          <Col span={12} className={styles.totalAmount}>
            {formatCurrencyPrice(total, property?.currency, property?.country)}
          </Col>
        </Row>

        <Button
          onClick={handleOpenCheckoutPage}
          className={classNames(styles.submitButton, 'blackButton', 'highlighted')}
          type="primary"
          htmlType="submit"
          size="large"
        >
          {t('gift_card.make_a_purchase')}
        </Button>
      </div>
    </div>
  );
};

export default GiftCardStoreCart;
