import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Row, Col, ConfigProvider, ConfigProviderProps } from 'antd';
import { Rule } from 'rc-field-form/lib/interface';
import PhoneInput, { locale, PhoneNumber } from 'antd-phone-input';

import { getLocaleFromI18nLanguage } from 'utils/get_locale_from_i18n_language';
import styles from './gift_card_store_billing_info.module.scss';
import { IGiftCardStoreBillingInfoProps } from './gift_card_store_billing_info.types';

type ExtendedLocale = ConfigProviderProps['locale'] & {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  PhoneInput?: any;
};

const GiftCardStoreBillingInfo: React.FC<IGiftCardStoreBillingInfoProps> = ({ form }) => {
  const { t, i18n } = useTranslation();

  const validatePhone = (_: Rule, { valid }: PhoneNumber): Promise<void> => {
    if (valid === undefined) {
      return Promise.resolve();
    }
    if (valid()) {
      return Promise.resolve();
    }

    return Promise.reject(new Error(t('validation_messages.required')));
  };

  const handlePhoneChange = (value: PhoneNumber) => {
    form.setFieldValue(['billing', 'phone'], value);
  };

  const requiredRules = [{ required: true, message: t('validation_messages.required') }];
  const requiredPhoneRules = [{ required: true, message: t('validation_messages.required'), validator: validatePhone }];

  return (
    <div data-testid="GiftCardStoreBillingInfo">
      <Row gutter={16} className={styles.itemRow}>
        <Col xs={24} sm={12}>
          <Form.Item name={['billing', 'name']} rules={requiredRules} label={t('general.payer_name')}>
            <Input />
          </Form.Item>
          <Form.Item
            name={['billing', 'phone']}
            rules={requiredPhoneRules}
            label={t('general.payer_number')}
            data-testid="PayerPhoneInput"
          >
            <ConfigProvider locale={locale(getLocaleFromI18nLanguage(i18n.language)) as ExtendedLocale}>
              <PhoneInput
                enableSearch
                disableParentheses
                value={form.getFieldValue(['billing', 'phone'])}
                onChange={handlePhoneChange}
              />
            </ConfigProvider>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name={['billing', 'address']} rules={requiredRules} label={t('general.payer_address')}>
            <Input />
          </Form.Item>
          <Form.Item
            name={['billing', 'email']}
            rules={[...requiredRules, { type: 'email', message: t('general.please_enter_valid_email') }]}
            label={t('general.payer_email')}
            getValueFromEvent={e => e.target.value.trim()}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default GiftCardStoreBillingInfo;
