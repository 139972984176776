import countries from 'constants/countries';

const formatCurrencyPrice = (price: number, currency: string, countryCode: string): string => {
  const languageCode = countries?.find(c => c.value === countryCode)?.languageCode || 'de-DE';

  const formatter = new Intl.NumberFormat(languageCode, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 10,
    currencyDisplay: 'narrowSymbol',
  });

  return formatter.format(price);
};

export default formatCurrencyPrice;
