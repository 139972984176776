// Generated with util/create-component.js
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import CancellationPoliciesList from 'components/cancellation_policies_list/cancellation_policies_list';
import PropertyHotelPolicy from 'components/property_policy/property_policy';
import {
  PropertyPoliciesDataContext,
  PropertySettingsDataContext,
  PropertyPoliciesActionsContext,
} from 'containers/data_context';

import styles from './property_policies.module.scss';

const loadingIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

const PropertyPolicies: React.FC = () => {
  const { t } = useTranslation();

  const {
    cancellationPolicies: { data: cancellationPoliciesList, isLoading: isLoadingCancellationPolicies },
    propertyPolicies: { data: hotelPoliciesList, isLoading: isLoadingHotelPolicies },
  } = useContext(PropertyPoliciesDataContext);

  const { selectedProperty } = useContext(PropertySettingsDataContext);

  const { loadPropertyCancellationPolicies, loadPropertyPolicies } = useContext(PropertyPoliciesActionsContext);

  useEffect(() => {
    if (selectedProperty) {
      loadPropertyCancellationPolicies({ propertyId: selectedProperty });
      loadPropertyPolicies({ propertyId: selectedProperty });
    }
  }, [selectedProperty]);

  if (isLoadingCancellationPolicies || isLoadingHotelPolicies) {
    return (
      <div className={styles.loading_container} data-testid="LoadingSpinner">
        <Spin indicator={loadingIcon} />
      </div>
    );
  }

  return (
    <div data-testid="PropertyPolicies" className={styles.root}>
      <div className={styles.title}>{t('payment_page.policy_info.hotel_policy')}</div>
      <PropertyHotelPolicy propertyPolicy={hotelPoliciesList?.[0] || null} />
      <div className={styles.title}>{t('property.cancellation_policies')}</div>
      <CancellationPoliciesList cancellationPolicies={cancellationPoliciesList} />
    </div>
  );
};

export default PropertyPolicies;
