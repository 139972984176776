import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Radio, Button, InputNumber, Statistic, Row, Col, Select, FormInstance, DatePicker } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';

import PURCHASE_TYPE from 'constants/gift_card_purchase';
import notification from 'utils/notification/notification';
import { currencyMap } from 'constants/currency_map';
import dateFormatter from 'utils/date_formatter';

import { IGiftCardIssueFormProps } from './gift_card_issue_form.types';
import styles from './gift_card_issue_form.module.scss';

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
};

const GiftCardIssueForm: React.FC<IGiftCardIssueFormProps> = ({
  giftCardType,
  giftCardTypeList,
  currency,
  onCancel,
  onSaveChanges,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const netPrice = Form.useWatch('netPrice', form);
  const quantity = Form.useWatch('quantity', form);
  const giftCardTypeSelected = Form.useWatch('giftCardType', form);
  const recipientsInfo = Form.useWatch('recipientsInfo', form);

  const requiredRules = [{ required: true, message: t('validation_messages.required') }];
  const initialValues = {
    netPrice: giftCardType?.price || 0,
    purchaseState: PURCHASE_TYPE.UNPAID,
    quantity: 1,
    customExpirationDate: null,
  };

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [isAddingRecipients, setIsAddingRecipients] = useState<boolean>(false);
  const [unpaidDisabled, setUnpaidDisabled] = useState<boolean>(true);
  const [prevQuantity, setPrevQuantity] = useState<number>(initialValues.quantity);

  useEffect(() => {
    if (quantity === 1) {
      setUnpaidDisabled(true);
      form.setFieldValue('purchaseState', PURCHASE_TYPE.PAID);
    } else if (prevQuantity === 1 && (quantity > 1 || quantity < 100)) {
      setUnpaidDisabled(false);
      form.setFieldValue('purchaseState', PURCHASE_TYPE.UNPAID);
    }
    setPrevQuantity(quantity);

    if (!isAddingRecipients || quantity === recipientsInfo?.length || quantity === null) {
      return;
    }

    if (recipientsInfo?.length > quantity) {
      form.setFieldValue('recipientsInfo', recipientsInfo.slice(0, quantity));
    } else {
      form.setFieldValue('recipientsInfo', [
        ...recipientsInfo,
        ...Array.from({ length: quantity - recipientsInfo.length }, () => ({ name: '', email: '' })),
      ]);
    }
  }, [quantity]);

  const handleSubmit = async (): Promise<void> => {
    const selectedGiftCard = giftCardType?.id || giftCardTypeSelected;

    if (!selectedGiftCard) return;
    const updatedValues = form.getFieldsValue(true);
    const formattedValues = {
      ...updatedValues,
      customExpirationDate: dateFormatter.toApi(updatedValues.customExpirationDate),
    };

    try {
      setSubmitLoading(true);
      await onSaveChanges(selectedGiftCard, formattedValues);

      notification.withIcon('success', t('gift_card.issued_notification'));
      onCancel();
    } catch (e) {
      console.log(e);
      notification.withIcon('error', t('general.error_description'));
    }
    setSubmitLoading(false);
  };

  const propertyTypeOptions = [
    { value: PURCHASE_TYPE.PAID, label: t('gift_card.purchase.paid') },
    { value: PURCHASE_TYPE.UNPAID, label: t('gift_card.purchase.unpaid'), disabled: unpaidDisabled },
  ];

  const totalPrice = () => {
    return netPrice * quantity || 0;
  };

  const setProductPrice = (id: number) => {
    if (!giftCardTypeList) return;

    const giftCardType = giftCardTypeList.find(card => card.id === id);
    form.setFieldsValue({ netPrice: giftCardType?.price });
  };

  const priceFormatter = (value: string | undefined) => {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const handleCopyClick = (
    getFieldValue: FormInstance['getFieldValue'],
    setFieldsValue: FormInstance['setFieldsValue'],
  ) => {
    setFieldsValue({
      recipientsInfo: Array.from({ length: quantity }, () => getFieldValue('recipientsInfo')[0]),
    });
  };

  const handleToggleRecipients = (setFieldsValue: FormInstance['setFieldsValue']) => {
    if (isAddingRecipients) {
      setFieldsValue({ recipientsInfo: null });
      setIsAddingRecipients(false);
    } else {
      setFieldsValue({
        recipientsInfo: Array.from({ length: quantity }, () => ({ name: '', email: '' })),
      });
      setIsAddingRecipients(true);
    }
  };

  const handlePaste =
    (index: number, startColumn: number, setFieldsValue: FormInstance['setFieldsValue']) =>
    async (event: React.ClipboardEvent) => {
      event.preventDefault();
      const text = event.clipboardData.getData('text');
      const rows = text.split(/\r\n|\n|\r/).filter(row => row.trim() !== '');
      const newRecipients = [...recipientsInfo];

      const rowLength = rows.length;
      let editedRows = 0;
      for (let i = index; i < quantity; i++) {
        if (editedRows === rowLength) {
          break;
        }

        const splitRow = rows[editedRows].split('\t');
        const name = splitRow[0];
        const email = splitRow[1];

        // Only 1 value on clipboard
        if (i === index && splitRow.length === 1) {
          if (startColumn === 0) {
            newRecipients[i].name = splitRow[0];
          } else if (startColumn === 1) {
            newRecipients[i].email = splitRow[0];
          }
          // Skip first value if pasted in from the email field
        } else if (i === index && startColumn === 1) {
          newRecipients[i].email = email;
        } else {
          newRecipients[i].name = name;
          newRecipients[i].email = email;
        }
        editedRows++;
      }

      setFieldsValue({ recipientsInfo: newRecipients });
      form.validateFields();
    };

  return (
    <div data-testid="GiftCardIssueForm" className={styles.root}>
      <Form
        name="GiftCardIssueForm"
        onFinish={handleSubmit}
        form={form}
        initialValues={initialValues}
        {...formItemLayout}
      >
        {giftCardTypeList && (
          <Form.Item name="giftCardType" label={t('gift_card.purchase.product')} rules={requiredRules}>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={true}
              placeholder={t('gift_card.purchase.product')}
              onChange={setProductPrice}
            >
              {giftCardTypeList.map((giftCardType, index) => (
                <Select.Option key={index} value={giftCardType.id}>
                  {giftCardType.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {giftCardType && (
          <Form.Item label={t('gift_card.purchase.product')} rules={requiredRules}>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={true}
              placeholder={t('gift_card.purchase.product')}
              disabled
              defaultValue={giftCardType.title}
            ></Select>
          </Form.Item>
        )}

        <Form.Item name="name" rules={[...requiredRules, { max: 80 }]} label={t('general.other.name')}>
          <Input placeholder={t('general.other.name')} />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[...requiredRules, { type: 'email', message: t('general.please_enter_valid_email') }]}
          label={t('general.other.email')}
        >
          <Input placeholder={t('general.other.email')} />
        </Form.Item>

        <Form.Item name="companyRegistrationNumber" label={t('gift_card.other.kennitala')}>
          <Input placeholder={t('gift_card.other.kennitala')} />
        </Form.Item>

        <Form.Item name="phone" label={t('general.other.phone')}>
          <Input placeholder={t('general.other.phone')} />
        </Form.Item>

        <Form.Item name="address" label={t('general.address')}>
          <Input placeholder={t('general.address')} />
        </Form.Item>

        <Form.Item name="city" label={t('general.city')}>
          <Input placeholder={t('general.city')} />
        </Form.Item>

        <Form.Item name="country" label={t('general.country')}>
          <Input placeholder={t('general.country')} />
        </Form.Item>

        <Form.Item
          name="netPrice"
          rules={[{ required: true, type: 'number', message: t('validation_messages.required'), min: 0 }]}
          label={t('gift_card.net_price')}
        >
          <InputNumber
            controls={false}
            addonAfter={currencyMap[currency]}
            placeholder={t('gift_card.net_price')}
            precision={0}
          />
        </Form.Item>

        <Form.Item
          name="quantity"
          rules={[{ required: true, type: 'number', message: t('validation_messages.required'), min: 1 }]}
          label={t('general.quantity')}
        >
          <InputNumber min={1} controls={true} placeholder={t('general.quantity')} precision={0} />
        </Form.Item>

        <Form.Item
          name="customExpirationDate"
          label={t('gift_card.purchase.custom_expiration_date')}
          tooltip={t('gift_card.purchase.custom_expiration_date_tooltip')}
        >
          <DatePicker disabledDate={current => current?.isBefore(dayjs().startOf('day'))} />
        </Form.Item>

        <Form.Item
          name="purchaseState"
          label={t('gift_card.purchase_state')}
          rules={requiredRules}
          tooltip={t('gift_card.purchase.purchase_state_tooltip')}
        >
          <Radio.Group options={propertyTypeOptions} optionType="button" buttonStyle="outline" />
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.recipientsInfo !== currentValues.recipientsInfo}
        >
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              name="recipients"
              label={t('gift_card.purchase.order_recipients')}
              tooltip={t('gift_card.purchase.recipients_tooltip')}
            >
              <Form.List name="recipientsInfo">
                {fields => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Row key={key} justify="space-between" align="top" gutter={4} className={styles.recipientsList}>
                        <Col xs={11}>
                          <Form.Item
                            {...restField}
                            name={[name, 'name']}
                            rules={[{ required: true, message: t('gift_card.purchase.missing_name') }]}
                          >
                            <Input
                              placeholder={t('general.other.name')}
                              onPaste={handlePaste(index, 0, setFieldsValue)}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={11}>
                          <Form.Item
                            {...restField}
                            name={[name, 'email']}
                            rules={[
                              ...requiredRules,
                              { type: 'email', message: t('general.please_enter_valid_email') },
                            ]}
                          >
                            <Input
                              placeholder={t('general.other.email')}
                              onPaste={handlePaste(index, 1, setFieldsValue)}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={1} className={styles.copyButton}>
                          {index === 0 && (
                            <CopyOutlined onClick={() => handleCopyClick(getFieldValue, setFieldsValue)} />
                          )}
                        </Col>
                      </Row>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        disabled={false}
                        onClick={() => handleToggleRecipients(setFieldsValue)}
                        block
                      >
                        {isAddingRecipients
                          ? t('gift_card.purchase.remove_recipients')
                          : t('gift_card.purchase.add_recipients')}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item>
          )}
        </Form.Item>

        <div className={styles.submit_section}>
          <div className={styles.total_net_price}>
            <Statistic
              title={`${t('general.total_price')} (${currency})`}
              prefix={`${quantity}x ${priceFormatter(netPrice)} = `}
              value={totalPrice()}
              groupSeparator="."
            />
          </div>

          <div className={styles.button_section}>
            <Button
              loading={submitLoading}
              className={styles.submit_button}
              type="primary"
              htmlType="submit"
              size="large"
            >
              {t('gift_card.create_order')}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default GiftCardIssueForm;
