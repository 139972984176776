import {
  SET_PROPERTIES_LIST,
  SET_PROPERTIES_LIST_LOADING,
  CLEAR_PROPERTIES_LIST,
  SET_SELECTED_PROPERTY,
  SET_PROPERTY_SETTINGS,
  SET_PROPERTY_SETTINGS_LOADING,
  SET_PROPERTY_FACILITIES,
  SET_PROPERTY_FACILITIES_LOADING,
  SET_PROPERTY_PHOTO_UPLOAD,
  SET_PROPERTY_SETTINGS_UPDATE_LOADING,
  SET_PROPERTY_GIFT_CARDS_STORE_SETTINGS_UPDATE_LOADING,
  SET_PROPERTY_GIFT_CARDS_STORE_SLUG_CREATE_LOADING,
  SET_PROPERTY_POLICIES,
  SET_PROPERTY_AUTH_TOKEN,
  RESET_PROPERTY_SETTINGS,
  SET_PROPERTY_GROUP_GIFT_CARDS_STORES_LOADING,
  SET_PROPERTY_GROUP_GIFT_CARDS_STORES,
  SET_PROPERTY_GIFT_CARDS_STORES_LOADING,
  SET_PROPERTY_GIFT_CARDS_STORES,
  SET_PROPERTY_GIFT_CARDS_STORE_LOADING,
  SET_PROPERTY_GIFT_CARDS_STORE,
  SET_NEW_PROPERTY_GIFT_CARDS_STORE,
  SET_UPDATED_PROPERTY_GIFT_CARDS_STORE,
} from './property_settings_actions';

const EMPTY_ENTITY = {
  data: null,
  isLoading: false,
};

export const INITIAL_STATE = {
  propertiesList: EMPTY_ENTITY,
  propertyDetails: EMPTY_ENTITY,
  propertyFacilities: EMPTY_ENTITY,
  propertyGroupGiftCardsStores: EMPTY_ENTITY,
  propertyGiftCardsStores: EMPTY_ENTITY,
  propertyGiftCardsStore: EMPTY_ENTITY,
  propertyPolicies: {
    propertyPolicies: [],
    cancellationPolicies: [],
  },
  photoUpload: EMPTY_ENTITY,
  isUpdatingDetails: false,
  isUpdatingGiftCardsStoreSettings: false,
  isCreatingGiftCardsStoreSlug: false,
  selectedProperty: null,
  propertyAuthToken: null,
};

const actionHandlers = {
  [SET_PROPERTIES_LIST_LOADING]: state => {
    const propertiesList = { ...state.propertiesList, isLoading: true };

    return { ...state, propertiesList };
  },
  [SET_PROPERTIES_LIST]: (state, action) => {
    const propertiesList = { data: action.payload, isLoading: false };

    return { ...state, propertiesList };
  },
  [CLEAR_PROPERTIES_LIST]: state => {
    const propertiesList = { data: [], isLoading: false };

    return { ...state, propertiesList };
  },
  [SET_SELECTED_PROPERTY]: (state, action) => {
    const selectedProperty = action.payload;
    return { ...state, selectedProperty };
  },
  [SET_PROPERTY_SETTINGS_LOADING]: state => {
    const propertyDetails = { ...state.propertyDetails, isLoading: true };

    return { ...state, propertyDetails };
  },
  [SET_PROPERTY_SETTINGS]: (state, action) => {
    const propertyDetails = { data: action.payload, isLoading: false };

    return { ...state, propertyDetails };
  },
  [SET_PROPERTY_POLICIES]: (state, action) => {
    const propertyPolicies = action.payload;

    return { ...state, propertyPolicies };
  },
  [SET_PROPERTY_FACILITIES_LOADING]: state => {
    const propertyFacilities = { ...state.propertyFacilities, isLoading: true };

    return { ...state, propertyFacilities };
  },
  [SET_PROPERTY_FACILITIES]: (state, action) => {
    const propertyFacilities = { data: action.payload, isLoading: false };

    return { ...state, propertyFacilities };
  },
  [SET_PROPERTY_GROUP_GIFT_CARDS_STORES_LOADING]: (state, action) => {
    const propertyGroupGiftCardsStores = { ...state.propertyGroupGiftCardsStores, isLoading: action.payload };

    return { ...state, propertyGroupGiftCardsStores };
  },
  [SET_PROPERTY_GROUP_GIFT_CARDS_STORES]: (state, action) => {
    const propertyGroupGiftCardsStores = { data: action.payload, isLoading: false };

    return { ...state, propertyGroupGiftCardsStores };
  },
  [SET_PROPERTY_GIFT_CARDS_STORES_LOADING]: (state, action) => {
    const propertyGiftCardsStores = { ...state.propertyGiftCardsStores, isLoading: action.payload };

    return { ...state, propertyGiftCardsStores };
  },
  [SET_PROPERTY_GIFT_CARDS_STORES]: (state, action) => {
    const propertyGiftCardsStores = { data: action.payload, isLoading: false };

    return { ...state, propertyGiftCardsStores };
  },
  [SET_PROPERTY_GIFT_CARDS_STORE_LOADING]: (state, action) => {
    const propertyGiftCardsStore = { ...state.propertyGiftCardsStore, isLoading: action.payload };

    return { ...state, propertyGiftCardsStore };
  },
  [SET_PROPERTY_GIFT_CARDS_STORE]: (state, action) => {
    const propertyGiftCardsStore = { data: action.payload, isLoading: false };

    return { ...state, propertyGiftCardsStore };
  },
  [SET_NEW_PROPERTY_GIFT_CARDS_STORE]: (state, action) => {
    const propertyGiftCardsStores = {
      ...state.propertyGiftCardsStores,
      data: [...state.propertyGiftCardsStores.data, action.payload],
      isLoading: false,
    };

    return { ...state, propertyGiftCardsStores };
  },
  [SET_UPDATED_PROPERTY_GIFT_CARDS_STORE]: (state, action) => {
    const updatedPropertyGiftCardsStore = action.payload;
    const propertyGiftCardsStores = {
      ...state.propertyGiftCardsStores,
      data: state.propertyGiftCardsStores.data.map(propertyGiftCardsStore => {
        if (propertyGiftCardsStore.id === updatedPropertyGiftCardsStore.id) {
          return updatedPropertyGiftCardsStore;
        }
        return propertyGiftCardsStore;
      }),
    };

    return { ...state, propertyGiftCardsStores };
  },
  [SET_PROPERTY_PHOTO_UPLOAD]: (state, action) => {
    const photoUpload = { data: action.payload, isLoading: false };

    return { ...state, photoUpload };
  },
  [SET_PROPERTY_SETTINGS_UPDATE_LOADING]: (state, { params }) => {
    return { ...state, isUpdatingDetails: params };
  },
  [SET_PROPERTY_GIFT_CARDS_STORE_SETTINGS_UPDATE_LOADING]: (state, { params }) => {
    return { ...state, isUpdatingGiftCardsStoreSettings: params };
  },
  [SET_PROPERTY_GIFT_CARDS_STORE_SLUG_CREATE_LOADING]: (state, { params }) => {
    return { ...state, isCreatingGiftCardsStoreSlug: params };
  },
  [SET_PROPERTY_AUTH_TOKEN]: (state, action) => {
    return { ...state, propertyAuthToken: action.payload };
  },
  [RESET_PROPERTY_SETTINGS]: state => {
    return {
      ...INITIAL_STATE,
      propertiesList: state.propertiesList,
      selectedProperty: state.selectedProperty,
    };
  },
};

export const reducer = (state, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state;
};
