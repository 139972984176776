import { useCallback, useMemo, useReducer } from 'react';

import { actions, INITIAL_STATE, reducer } from './reducers/property_settings_data';

export default () => {
  const [propertySettingsData, dispatch] = useReducer(reducer, INITIAL_STATE);

  const setSelectedProperty = useCallback(
    params => {
      return actions.setSelectedProperty(dispatch, params);
    },
    [dispatch],
  );

  const loadSuppliersList = useCallback(
    params => {
      return actions.loadSuppliersList(dispatch, params);
    },
    [dispatch],
  );

  const clearSuppliersList = useCallback(
    params => {
      return actions.clearPropertiesList(dispatch, params);
    },
    [dispatch],
  );

  const loadPropertySettings = useCallback(
    params => {
      return actions.loadPropertySettings(dispatch, params);
    },
    [dispatch],
  );

  const updatePropertySettings = useCallback(
    params => {
      return actions.updatePropertySettings(dispatch, params);
    },
    [dispatch],
  );

  const updatePropertyGiftCardsStoreSettings = useCallback(
    params => {
      return actions.updatePropertyGiftCardsStoreSettings(dispatch, params);
    },
    [dispatch],
  );

  const createPropertyGiftCardsStoreSlug = useCallback(
    params => {
      return actions.createPropertyGiftCardsStoreSlug(dispatch, params);
    },
    [dispatch],
  );

  const loadPropertyFacilities = useCallback(
    params => {
      return actions.loadPropertyFacilities(dispatch, params);
    },
    [dispatch],
  );

  const loadPropertyGroupGiftCardsStores = useCallback(
    params => {
      return actions.loadPropertyGroupGiftCardsStores(dispatch, params);
    },
    [dispatch],
  );

  const loadPropertyGiftCardsStores = useCallback(
    params => {
      return actions.loadPropertyGiftCardsStores(dispatch, params);
    },
    [dispatch],
  );

  const loadPropertyGiftCardsStore = useCallback(
    params => {
      return actions.loadPropertyGiftCardsStore(dispatch, params);
    },
    [dispatch],
  );

  const createPropertyGiftCardsStore = useCallback(
    params => {
      return actions.createPropertyGiftCardsStore(dispatch, params);
    },
    [dispatch],
  );
  const updatePropertyGiftCardsStore = useCallback(
    params => {
      return actions.updatePropertyGiftCardsStore(dispatch, params);
    },
    [dispatch],
  );

  const uploadPropertyPhoto = useCallback(
    params => {
      return actions.uploadPropertyPhoto(dispatch, params);
    },
    [dispatch],
  );

  const loadOneTimeHotelPropertyAuthToken = useCallback(
    params => {
      return actions.loadOneTimePropertyAuthToken(dispatch, params);
    },
    [dispatch],
  );

  const resetSupplierSettings = useCallback(() => {
    return actions.resetSupplierSettings(dispatch);
  }, [dispatch]);

  const propertySettingsActions = useMemo(
    () => ({
      setSelectedProperty,
      loadSuppliersList,
      clearSuppliersList,
      loadPropertySettings,
      loadPropertyFacilities,
      loadPropertyGroupGiftCardsStores,
      loadPropertyGiftCardsStores,
      loadPropertyGiftCardsStore,
      createPropertyGiftCardsStore,
      updatePropertyGiftCardsStore,
      uploadPropertyPhoto,
      updatePropertySettings,
      updatePropertyGiftCardsStoreSettings,
      createPropertyGiftCardsStoreSlug,
      loadOneTimeHotelPropertyAuthToken,
      resetSupplierSettings,
    }),
    [
      setSelectedProperty,
      loadSuppliersList,
      clearSuppliersList,
      loadPropertySettings,
      loadPropertyFacilities,
      loadPropertyGroupGiftCardsStores,
      loadPropertyGiftCardsStores,
      loadPropertyGiftCardsStore,
      createPropertyGiftCardsStore,
      updatePropertyGiftCardsStore,
      uploadPropertyPhoto,
      updatePropertySettings,
      updatePropertyGiftCardsStoreSettings,
      createPropertyGiftCardsStoreSlug,
      loadOneTimeHotelPropertyAuthToken,
      resetSupplierSettings,
    ],
  );

  return useMemo(
    () => ({ propertySettingsData, propertySettingsActions }),
    [propertySettingsData, propertySettingsActions],
  );
};
