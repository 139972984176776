import React, { useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useMedia } from 'react-media';
import { Button, Result, Row, Col } from 'antd';
import { CheckSquareFilled, CloseSquareFilled } from '@ant-design/icons';

import routes from 'routing/routes';
import buildPath from 'utils/build_path';
import dateFormatter from 'utils/date_formatter';
import formatCurrencyPrice from 'utils/format_currency_price';
import MEDIA_QUERIES from 'constants/media_queries';

import { IGiftCardStoreConfirmationProps } from './gift_card_store_confirmation.types';
import styles from './gift_card_store_confirmation.module.scss';

const GiftCardStoreConfirmation: React.FC<IGiftCardStoreConfirmationProps> = ({ orderData, purchaseStatus }) => {
  const matchedQueries = useMedia({ queries: MEDIA_QUERIES });
  const isMobile = matchedQueries.xs || matchedQueries.sm || matchedQueries.md;

  const history = useHistory();
  const { t } = useTranslation();

  const { selectedProperty } = useParams<{ selectedProperty: string }>();

  const renderOrders = useMemo(
    () => (
      <Row>
        {!isMobile && (
          <Col sm={24} xs={24} className={classNames(styles.header, 'highlighted')}>
            <Row className={styles.headerContent}>
              <Col sm={9} xs={24} className={styles.alignCenter}>
                {t('gift_card')}
              </Col>
              <Col xs={0} sm={5} className={styles.alignCenter}>
                {t('general.expires')}
              </Col>
              <Col xs={0} sm={5} className={styles.alignCenter}>
                {t('general.sent_to')}
              </Col>
              <Col xs={0} sm={5} className={styles.alignCenter}>
                {t('gift_card.certificate_number')}
              </Col>
            </Row>
          </Col>
        )}
        {[...orderData]?.map(order => (
          <Col key={order.generatedId} data-testid="Order" sm={24} xs={24}>
            <Row className={styles.orderRoom}>
              {isMobile && (
                <Col xs={24} className={styles.mobileHeader}>
                  {t('gift_card')}
                </Col>
              )}
              <Col sm={9} xs={24}>
                <Row>
                  <Col sm={5}>
                    <img className={classNames(styles.photo, 'roundedWrapper')} src={order?.photoUrl} alt="Room" />
                  </Col>
                  <Col sm={18} offset={1} className="highlighted">
                    <Row>
                      <Col sm={24} className={styles.roomTitle}>
                        {order.name}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={24} className={styles.roomPrice}>
                        {formatCurrencyPrice(order.price || 0, order.currency, order.country)}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col sm={5} xs={0} className={styles.roomDetails}>{`${dateFormatter.toUi(order.expirationDate)}`}</Col>
              <Col sm={5} xs={0} className={styles.roomDetails}>
                {order.nameOfPerson}
              </Col>
              <Col sm={5} xs={0} className={styles.giftCertificateNumber}>
                {order.generatedId}
              </Col>

              <Col sm={0} xs={24} className={styles.roomDetailsWrapper}>
                <span className={styles.roomDetailsLabel}>{t('general.sent_to')}: </span>
                <span className={styles.roomDetails}>{order.nameOfPerson}</span>
              </Col>
              <Col sm={0} xs={24} className={styles.roomDetailsWrapper}>
                <span className={styles.roomDetailsLabel}>{t('general.expires_on')}: </span>
                <span className={styles.roomDetails}>{`${dateFormatter.toUi(order.expirationDate)}`}</span>
              </Col>
              <Col sm={0} xs={24} className={styles.roomDetailsWrapper}>
                <span className={styles.roomDetailsLabel}>{t('gift_card.certificate_number')}: </span>
                <span className={styles.roomDetails}>{order.generatedId}</span>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    ),
    [orderData],
  );

  const handleViewMoreCerificates = useCallback(() => {
    const path = buildPath('', routes.giftCardStorePage, {
      selectedProperty,
    });

    history.push(path);
  }, [selectedProperty]);

  const purchaseTitle = useMemo(() => {
    if (purchaseStatus.reason && !purchaseStatus.success) {
      return purchaseStatus.reason;
    }
    return t('gift_card.order_is_confirmed');
  }, [purchaseStatus]);

  const purchaseSubTitle = useMemo(() => {
    if (purchaseStatus.reason && !purchaseStatus.success) return '';
    return t('gift_card.order_details');
  }, [purchaseStatus]);

  return (
    <div className={classNames(styles.root, 'roundedWrapper')} data-testid="GiftCardStoreConfirmation">
      <Result
        icon={
          purchaseStatus.success ? (
            <CheckSquareFilled style={{ color: '#0F1015' }} />
          ) : (
            <CloseSquareFilled style={{ color: '#0F1015' }} />
          )
        }
        status={purchaseStatus.success ? 'success' : 'error'}
        subTitle={<div className={styles.resultSubtitle}>{purchaseSubTitle}</div>}
        title={<div className={classNames(styles.resultTitle, 'highlighted')}>{purchaseTitle}</div>}
      />
      <div className={classNames(styles.orderSummary, 'highlighted')}>{t('general.order_summary')}</div>

      {renderOrders}
      <div className={styles.buttonWrapper}>
        <Button
          size="large"
          className={classNames(styles.storeButton, 'highlighted')}
          key="button"
          onClick={handleViewMoreCerificates}
        >
          {t('gift_card.view_more_certificates')}
        </Button>
      </div>
    </div>
  );
};

export default GiftCardStoreConfirmation;
