// Generated with util/create-component.js
import React from 'react';
import { Row, Col } from 'antd';
import classNames from 'classnames';

import calculatePercentage from 'utils/calculate_percentage';
import { SHARP_CORNERS } from 'constants/store_settings_corner_style';

import themeStyles from 'components/gift_card_buy_store_page_layout/gift_card_store_theme.module.scss';

import formatCurrencyPrice from 'utils/format_currency_price';
import { IGiftCardStoreCartSummaryProps } from './gift_card_store_cart_summary.types';
import styles from './gift_card_store_cart_summary.module.scss';

const GiftCardStoreCartSummary: React.FC<IGiftCardStoreCartSummaryProps> = ({ cartItems, storeSettings, property }) => {
  const renderCart = cartItems.map((item, index) => {
    return (
      <Row key={index} className={styles.itemRow}>
        <Col span={10} className={styles.itemPhoto}>
          <img src={item?.item?.photos?.[0]?.publicUrl} className={themeStyles.roundedWrapper} alt="Room" />
        </Col>
        <Col span={14} className={styles.textColumn}>
          <div className={classNames(styles.itemTitle, themeStyles.highlighted)}>{item.item.title}</div>
          <div className={classNames(styles.itemPrice, themeStyles.highlighted)}>
            {item.quantity} x {formatCurrencyPrice(item.item.netPrice, property?.currency, property?.country)}
            {item.item.netPrice !== item.item.price && calculatePercentage(item.item.netPrice, item.item.price) && (
              <span className={classNames(styles.discountPercentage, themeStyles.discountPercentage)}>
                {calculatePercentage(item.item.netPrice, item.item.price)}
              </span>
            )}
          </div>
          {item.item.netPrice !== item.item.price && (
            <div className={themeStyles.discountPrice}>
              {item.quantity} x {formatCurrencyPrice(item.item.price, property?.currency, property?.country)}
            </div>
          )}
        </Col>
      </Row>
    );
  });

  return (
    <div
      data-testid="GiftCardStoreCartSummary"
      className={classNames(
        themeStyles.root,
        storeSettings?.cornerStyle === SHARP_CORNERS ? '' : themeStyles.roundedTheme,
      )}
    >
      <div className={styles.cartItemsWrapper}>{renderCart}</div>
    </div>
  );
};

export default GiftCardStoreCartSummary;
