// Generated with util/create-component.js
import React, { useState, useContext, useEffect } from 'react';
import { Table, Tooltip, Popconfirm, Drawer, Dropdown, Button, MenuProps } from 'antd';
import { DownOutlined, EditOutlined, DeleteOutlined, PlusOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

import DRAWER_SIZE from 'constants/drawer_size';

import PropertyUnitForm from 'components/property_unit_form/property_unit_form';
import { IRatePlanProps } from 'components/rates_table/property_unit/rate_plan/rate_plan.types';
import PropertyRatePlanForm from 'components/property_rate_plan_form/property_rate_plan_form';
import PropertyRatePlansPerUnit from 'components/property_rate_plans_per_unit/property_rate_plans_per_unit';

import {
  PropertyUnitsDataContext,
  PropertyUnitsActionsContext,
  PropertySettingsDataContext,
} from 'containers/data_context';

import { IPropertyUnit } from './property_units.types';
import styles from './property_units.module.scss';

const PropertyUnits: React.FC = () => {
  const { t } = useTranslation();

  const {
    propertyUnits: { data: units, isLoading },
    isRemovingUnit,
  } = useContext(PropertyUnitsDataContext);

  const { selectedProperty } = useContext(PropertySettingsDataContext);

  const { loadPropertyUnits, clearSelectedPropertyUnit, removePropertyUnit } = useContext(PropertyUnitsActionsContext);

  const [openedUnitForm, toogleUnitForm] = useState<boolean>(false);
  const [openedRatePlanForm, toogleRatePlanForm] = useState<boolean>(false);
  const [unitId, setUnitId] = useState<IPropertyUnit['id'] | null>(null);
  const [ratePlanId, setRatePlanId] = useState<IRatePlanProps['ratePlan']['id'] | null>(null);
  const [unitRatePlan, setUnitRatePlan] = useState<IPropertyUnit['id'] | null>(null);

  useEffect(() => {
    if (selectedProperty && !units?.length) {
      loadPropertyUnits(selectedProperty);
    }
  }, [selectedProperty, units]);

  const handleRemoveSelectedRoomType = (record: IPropertyUnit) => {
    removePropertyUnit({ propertyId: selectedProperty, unitId: record.id });
  };

  const handleOpenUnitForm = (unitId: IPropertyUnit['id'] | null) => {
    toogleUnitForm(true);
    setUnitId(unitId);
  };

  const handleCloseUnitForm = () => {
    toogleUnitForm(false);
    clearSelectedPropertyUnit();
    setUnitId(null);
  };

  const handleOpenRatePlanForm = (unitId: IPropertyUnit['id']) => {
    toogleRatePlanForm(true);
    setRatePlanId(null);
    setUnitRatePlan(unitId);
  };

  const handleCloseRatePlanForm = () => {
    toogleRatePlanForm(false);
    setUnitRatePlan(null);
  };

  const getDefaultUnitOccupancy = () => units?.find((rt: IPropertyUnit) => rt?.id === unitRatePlan)?.defaultOccupancy;

  const getMaxUnitOccupancy = () => units?.find((rt: IPropertyUnit) => rt?.id === unitRatePlan)?.occAdults;

  const unitsColumns: ColumnsType<IPropertyUnit> = [
    {
      title: t('general.title'),
      render: (record: IPropertyUnit) => <div>{record?.title}</div>,
      sorter: (r1: IPropertyUnit, r2: IPropertyUnit) => r1?.title?.localeCompare(r2?.title),
    },
    {
      title: t('general.count_of_rooms'),
      key: 'countOfRooms',
      render: (record: IPropertyUnit) => {
        return <div>{record?.countOfRooms}</div>;
      },
      sorter: (r1: IPropertyUnit, r2: IPropertyUnit) => r1?.countOfRooms - r2?.countOfRooms,
    },
    {
      title: t('general.occupancy'),
      responsive: ['sm'],
      render: (record: IPropertyUnit) => (
        <div>{`${record?.defaultOccupancy} (A:${record?.occAdults} C:${record?.occChildren} I:${record?.occInfants})`}</div>
      ),
    },
    {
      title: <div className={styles.actions_link_title}>{t('general.actions')}</div>,
      key: 'actions',
      render: (record: IPropertyUnit) => renderActionButtons(record),
    },
  ];

  const renderMenu = (record: IPropertyUnit): MenuProps => {
    const menuItems: MenuProps['items'] = [
      {
        key: 'edit',
        label: t('general.edit'),
        icon: <EditOutlined />,
        onClick: () => handleOpenUnitForm(record.id),
      },
      {
        key: 'create-rate',
        label: t('general.create_rate'),
        icon: <PlusOutlined />,
        onClick: () => handleOpenRatePlanForm(record.id),
      },
      {
        key: 'delete-rate',
        label: (
          <Tooltip title={`Remove ${record?.title} room`}>
            <Popconfirm
              title={
                <>
                  <p>{t('bookings_list.confirm')}</p>
                  <p>{`${t('general.are_you_sure')} ${record?.title} room?`}</p>
                </>
              }
              cancelText={t('link.cancel')}
              okText={t('link.yes')}
              placement="bottomRight"
              onConfirm={() => handleRemoveSelectedRoomType(record)}
              disabled={isRemovingUnit}
            >
              {t('general.remove')}
            </Popconfirm>
          </Tooltip>
        ),
        icon: <DeleteOutlined />,
      },
    ];
    return { items: menuItems };
  };

  const renderActionButtons = (record: IPropertyUnit) => {
    return (
      <Dropdown menu={renderMenu(record)}>
        <div className={styles.actions_link}>
          <span style={{ marginRight: 5 }}>{t('general.actions')}</span> <DownOutlined />
        </div>
      </Dropdown>
    );
  };

  return (
    <div data-testid="PropertyUnits" className={styles.root}>
      <div className={styles.title_container}>
        <div className={styles.title}>{t('general.rooms_and_rates')}</div>
        <Button
          onClick={() => handleOpenUnitForm(null)}
          type="primary"
          icon={<PlusCircleOutlined />}
          size="large"
          className={styles.button}
        >
          {t('general.create')}
        </Button>
      </div>
      <Table
        dataSource={units}
        loading={isLoading}
        columns={unitsColumns}
        pagination={false}
        rowKey="id"
        scroll={{ y: '75vh' }}
        expandable={{
          expandedRowRender: record => (
            <PropertyRatePlansPerUnit
              unitId={record.id}
              defaultOccupancy={record.defaultOccupancy}
              maxOccupancy={record.occAdults}
            />
          ),
        }}
      />
      <Drawer
        open={openedUnitForm}
        title={t('general.edit_room')}
        width={window.innerWidth > 900 ? DRAWER_SIZE.LARGE : window.innerWidth}
        onClose={handleCloseUnitForm}
        destroyOnClose={true}
      >
        <PropertyUnitForm unitId={unitId} onCancel={handleCloseUnitForm} />
      </Drawer>

      <Drawer
        open={openedRatePlanForm}
        title={t('general.create_rate')}
        width={window.innerWidth > 900 ? DRAWER_SIZE.LARGE : window.innerWidth}
        onClose={handleCloseRatePlanForm}
        destroyOnClose={true}
      >
        <PropertyRatePlanForm
          onCancel={handleCloseRatePlanForm}
          ratePlanId={ratePlanId}
          unitId={unitRatePlan}
          defaultOccupancy={getDefaultUnitOccupancy()}
          maxOccupancy={getMaxUnitOccupancy()}
        />
      </Drawer>
    </div>
  );
};

export default PropertyUnits;
