// Generated with util/create-component.js
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Radio, Select, Tag } from 'antd';

import paymentProviders from 'constants/payment_providers';

import { IPaymentProviderFormProps } from './payment_provider_form.types';
import styles from './payment_provider_form.module.scss';

const initialValues = {
  name: paymentProviders.BORGUN,
  details: {
    accessKey: '',
    secretKey: '',
    checkoutKey: '',
    merchantId: '',
    paymentGatewayId: '',
    valitorIdentifier: '',
    valitorVerificationCode: '',
  },
  default: false,
};

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
};

const PaymentProviderForm: React.FC<IPaymentProviderFormProps> = ({ onCancel, isSaving, onSave, paymentProvider }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  useEffect(() => {
    if (paymentProvider) {
      const { name, details } = paymentProvider;
      form.setFieldsValue({ name, details, default: paymentProvider.default });
    }
  }, [paymentProvider]);

  const validateMessages = {
    required: t('validation_messages.required'),
  };

  const handleSubmit = async (): Promise<void> => {
    const updatedValues = form.getFieldsValue(true);

    const {
      valitorIdentifier,
      valitorVerificationCode,
      merchantId,
      paymentGatewayId,
      secretKey,
      checkoutKey,
      accessKey,
    } = updatedValues.details;

    if (updatedValues.name == paymentProviders.BORGUN) {
      updatedValues.details = {
        merchantId,
        paymentGatewayId,
        secretKey,
      };
    } else if (updatedValues.name == paymentProviders.VALITOR) {
      updatedValues.details = { valitorIdentifier, valitorVerificationCode };
    } else if (updatedValues.name == paymentProviders.NETS) {
      updatedValues.details = { secretKey, checkoutKey, merchantId };
    } else if (updatedValues.name == paymentProviders.RAPYD) {
      updatedValues.details = { secretKey, accessKey };
    }

    onSave(updatedValues);
  };

  const defaultProviderOptions = [
    { value: true, label: t('general.yes') },
    { value: false, label: t('general.no') },
  ];

  const paymentProvidersOptions = [
    {
      label: (
        <span>
          Valitor
          <Tag color="warning" style={{ marginLeft: 8 }}>
            {t('general.deprecated')}
          </Tag>
        </span>
      ),
      value: paymentProviders.VALITOR,
    },
    {
      label: 'Borgun',
      value: paymentProviders.BORGUN,
    },
    {
      label: 'Stripe',
      value: paymentProviders.STRIPE,
    },
    {
      label: 'Nets',
      value: paymentProviders.NETS,
    },
    {
      label: 'Rapyd',
      value: paymentProviders.RAPYD,
    },
  ];

  const requiredRules = [{ required: true }];

  const paymentMethodName = Form.useWatch('name', form);

  const fieldIsHidden = (currentField: string) => {
    return paymentMethodName !== currentField;
  };

  const fieldIsRequired = (currentField: string) => {
    return paymentMethodName === currentField;
  };

  const saveButtonTitle = () => {
    if (paymentMethodName === paymentProviders.STRIPE && !paymentProvider) return t('general.payment_providers.save');

    return t('general.buttons.save');
  };

  return (
    <div data-testid="PaymentProviderForm" className={styles.root}>
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        {...formItemLayout}
      >
        <Form.Item label={t('general.other.name')} name="name" rules={requiredRules}>
          <Select
            showSearch
            placeholder={t('general.other.name')}
            optionFilterProp="label"
            filterOption={true}
            options={paymentProvidersOptions}
            allowClear
            data-testid="PaymentProviderName"
          />
        </Form.Item>

        {/* rapyd */}
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.name !== currentValues.name}>
          {() => (
            <>
              <Form.Item
                label={t('general.access_key')}
                name={['details', 'accessKey']}
                rules={[{ required: fieldIsRequired(paymentProviders.RAPYD) }]}
                hidden={fieldIsHidden(paymentProviders.RAPYD)}
              >
                <Input placeholder={t('general.access_key')} />
              </Form.Item>
              <Form.Item
                label={t('general.secret_key')}
                name={['details', 'secretKey']}
                rules={[{ required: fieldIsRequired(paymentProviders.RAPYD) }]}
                hidden={fieldIsHidden(paymentProviders.RAPYD)}
              >
                <Input placeholder={t('general.secret_key')} />
              </Form.Item>
            </>
          )}
        </Form.Item>

        {/* borgun */}
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.name !== currentValues.name}>
          {() => (
            <>
              <Form.Item
                label={t('general.secret_key')}
                name={['details', 'secretKey']}
                rules={[{ required: fieldIsRequired(paymentProviders.BORGUN) }]}
                hidden={fieldIsHidden(paymentProviders.BORGUN)}
              >
                <Input placeholder={t('general.secret_key')} />
              </Form.Item>
              <Form.Item
                label={t('general.merchant_id')}
                name={['details', 'merchantId']}
                rules={[{ required: fieldIsRequired(paymentProviders.BORGUN) }]}
                hidden={fieldIsHidden(paymentProviders.BORGUN)}
              >
                <Input placeholder={t('general.merchant_id')} />
              </Form.Item>
              <Form.Item
                label={t('general.payment_gateway_id')}
                name={['details', 'paymentGatewayId']}
                rules={[{ required: fieldIsRequired(paymentProviders.BORGUN) }]}
                hidden={fieldIsHidden(paymentProviders.BORGUN)}
              >
                <Input placeholder={t('general.payment_gateway_id')} />
              </Form.Item>
            </>
          )}
        </Form.Item>

        {/* valitor */}
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.name !== currentValues.name}>
          {() => (
            <>
              <Form.Item
                label={t('general.valitor_identifier')}
                name={['details', 'valitorIdentifier']}
                rules={[{ required: fieldIsRequired(paymentProviders.VALITOR) }]}
                hidden={fieldIsHidden(paymentProviders.VALITOR)}
              >
                <Input placeholder={t('general.valitor_identifier')} />
              </Form.Item>
              <Form.Item
                label={t('general.valitor_verification_code')}
                name={['details', 'valitorVerificationCode']}
                rules={[{ required: fieldIsRequired(paymentProviders.VALITOR) }]}
                hidden={fieldIsHidden(paymentProviders.VALITOR)}
              >
                <Input placeholder={t('general.valitor_verification_code')} />
              </Form.Item>
            </>
          )}
        </Form.Item>

        {/* nets */}
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.name !== currentValues.name}>
          {() => (
            <>
              <Form.Item
                label={t('general.merchant_id')}
                name={['details', 'merchantId']}
                rules={[{ required: fieldIsRequired(paymentProviders.NETS) }]}
                hidden={fieldIsHidden(paymentProviders.NETS)}
              >
                <Input placeholder={t('general.merchant_id')} />
              </Form.Item>
              <Form.Item
                label={t('general.secret_key')}
                name={['details', 'secretKey']}
                rules={[{ required: fieldIsRequired(paymentProviders.NETS) }]}
                hidden={fieldIsHidden(paymentProviders.NETS)}
              >
                <Input placeholder={t('general.secret_key')} />
              </Form.Item>
              <Form.Item
                label={t('general.checkout_key')}
                name={['details', 'checkoutKey']}
                rules={[{ required: fieldIsRequired(paymentProviders.NETS) }]}
                hidden={fieldIsHidden(paymentProviders.NETS)}
              >
                <Input placeholder={t('general.checkout_key')} />
              </Form.Item>
            </>
          )}
        </Form.Item>

        <Form.Item name="default" label={t('general.set_as_default')}>
          <Radio.Group options={defaultProviderOptions} optionType="button" buttonStyle="solid" />
        </Form.Item>
        <div className={styles.footer}>
          <Form.Item>
            <Button type="default" onClick={onCancel}>
              {t('general.buttons.cancel')}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.submit_button}
              loading={isSaving}
              disabled={isSaving}
            >
              {saveButtonTitle()}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default PaymentProviderForm;
