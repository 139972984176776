export const localeMap = {
  en: 'enUS',
  es: 'esES',
  it: 'itIT',
  pt: 'ptPT',
  de: 'deDE',
  fr: 'frFR',
  el: 'elGR',
  is: 'isIS',
} as const;

export type LocaleType = (typeof localeMap)[keyof typeof localeMap];
