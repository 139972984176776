import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AuthenticationDataContext } from 'containers/data_context';

import GeneralPageLayout from 'components/general_page_layout/general_page_layout';
import ConfirmationPage from 'pages/confirmation_page';
import SearchCarsPage from 'pages/search_cars_page';
import SupplierPage from 'pages/property_page';
import LoginPage from 'pages/login_page';
import NotFoundPage from 'pages/not_found_page';
import BookingCheckoutPage from 'pages/booking_checkout_page';
import SearchPage from 'pages/search_page';
import BookingsPage from 'pages/bookings_page';
import ContractsPage from 'pages/contracts_page';
import InventoryPage from 'pages/inventory_page';
import AdminPage from 'pages/admin_page/admin_page';
import ApiKeysPage from 'pages/api_keys_page/api_keys_page';
import ChangePasswordPage from 'pages/change_password_page/change_password_page';
import PropertySettingsPage from 'pages/property_settings_page/property_settings_page';
import VerifyResetPasswordEmailPage from 'pages/verify_reset_password_email_page/verify_reset_password_email_page';
import GiftCardStoreBuyPageLayout from 'components/gift_card_buy_store_page_layout/gift_card_buy_store_page_layout';
import GiftCardStoreDetailPage from 'pages/gift_card_store_detail_page';
import GiftCardPropertiesListPage from 'pages/gift_card_properties_list_page/gift_card_properties_list_page';
import UseGiftCardStorePage from 'pages/use_gift_card_store_page/use_gift_card_store_page';
import CancelGiftCardStorePage from 'pages/cancel_gift_card_store_page/cancel_gift_card_store_page';
import GiftCardStoreTermsPage from 'pages/gift_card_store_terms_page/gift_card_store_terms_page';
import GiftCardStorePage from 'pages/gift_card_store_page';
import GiftCardPage from 'pages/gift_card_page';
import GiftCardOrderPage from 'pages/gift_card_orders_page';
import GiftCardPurchasePage from 'pages/gift_card_purchase_page';
import GiftCardStoreCheckoutPage from 'pages/gift_card_store_checkout_page';
import GiftCardStoreConfirmationPage from 'pages/gift_card_store_confirmation_page';
import GiftCardStoreCartPage from 'pages/gift_card_store_cart_page';
import GiftCardStorePaymentPage from 'pages/gift_card_store_payment_page';
import GiftCardStoreBookingPage from 'pages/gift_card_store_booking_page';
import GiftCardStoreBookingCheckoutPage from 'pages/gift_card_store_booking_checkout_page';
import GiftCardStoreBookingConfirmationPage from 'pages/gift_card_store_booking_confirmation_page';
import GiftCampaignsPage from 'pages/gift_campaigns_page/gift_campaigns_page';
import GiftCampaignPage from 'pages/gift_campaign_page/gift_campaign_page';

import GiftCampaignNavbar from 'components/gift_campaign_navbar/gift_campaign_navbar';
import PageLayout from 'components/page_layout/page_layout';

import USER_ROLE from 'constants/user_role';

import routes from './routes';
import styles from './routing.module.scss';

const PrivateRoute = ({ children, ...rest }) => {
  const { user } = useContext(AuthenticationDataContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          <PageLayout>{children}</PageLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const GiftCampaignRoute = ({ children, ...rest }) => {
  return (
    <Route {...rest} render={() => <GeneralPageLayout header={<GiftCampaignNavbar />}>{children}</GeneralPageLayout>} />
  );
};

const GiftCardStoreRoute = ({ className, children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => <GiftCardStoreBuyPageLayout className={className}>{children}</GiftCardStoreBuyPageLayout>}
    />
  );
};

const AdminRoute = ({ children, ...rest }) => {
  const { user } = useContext(AuthenticationDataContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user?.role === 'admin' ? (
          <PageLayout>{children}</PageLayout>
        ) : (
          <Redirect
            to={{
              pathname: user ? routes.bookingsPage : routes.loginPage,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const ResellerRoute = ({ children, ...rest }) => {
  const { user, userRole } = useContext(AuthenticationDataContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        userRole === USER_ROLE.RESELLER ? (
          <PageLayout>{children}</PageLayout>
        ) : (
          <Redirect
            to={{
              pathname: user ? routes.bookingsPage : routes.loginPage,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const PropertyRoute = ({ children, ...rest }) => {
  const { user, userRole } = useContext(AuthenticationDataContext);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        userRole === USER_ROLE.SUPPLIER || user?.role === 'admin' ? (
          <PageLayout>{children}</PageLayout>
        ) : (
          <Redirect
            to={{
              pathname: user ? routes.homePage : routes.loginPage,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default function Routing() {
  return (
    <Switch>
      <Route exact path={routes.default}>
        <NotFoundPage />
      </Route>
      <Route exact path={routes.loginPage}>
        <LoginPage />
      </Route>
      <Route exact path={routes.verifyResetPasswordEmail}>
        <VerifyResetPasswordEmailPage />
      </Route>
      <PrivateRoute path={routes.contractsPage} exact>
        <ContractsPage />
      </PrivateRoute>
      <PrivateRoute path={routes.giftCardPage} exact>
        <GiftCardOrderPage />
      </PrivateRoute>
      <PrivateRoute path={routes.ordersPage} exact>
        <GiftCardPurchasePage />
      </PrivateRoute>
      <PrivateRoute path={routes.bookingsPage} exact>
        <BookingsPage />
      </PrivateRoute>
      <PrivateRoute path={routes.changePassword} exact>
        <ChangePasswordPage />
      </PrivateRoute>

      <GiftCampaignRoute exact path={routes.giftCampaign}>
        <GiftCampaignPage />
      </GiftCampaignRoute>
      <PrivateRoute exact path={routes.giftCampaigns}>
        <GiftCampaignsPage />
      </PrivateRoute>

      <ResellerRoute path={routes.changePassword} exact>
        <ChangePasswordPage />
      </ResellerRoute>
      <ResellerRoute path={routes.apiKeysPage} exact>
        <ApiKeysPage />
      </ResellerRoute>
      <AdminRoute path={routes.adminPage} exact>
        <AdminPage />
      </AdminRoute>
      <ResellerRoute path={routes.searchPage} exact>
        <SearchPage />
      </ResellerRoute>
      <ResellerRoute path={routes.propertyPage} exact>
        <SupplierPage />
      </ResellerRoute>
      <ResellerRoute path={routes.homePage} exact>
        <Redirect to={routes.bookingsPage} />
      </ResellerRoute>
      <ResellerRoute path={routes.searchCarsPage} exact>
        <SearchCarsPage />
      </ResellerRoute>
      <ResellerRoute path={routes.checkoutPage} exact>
        <BookingCheckoutPage />
      </ResellerRoute>
      <ResellerRoute path={routes.confirmationPage} exact>
        <ConfirmationPage />
      </ResellerRoute>

      <PropertyRoute path={routes.searchCarsPage} exact>
        <SearchCarsPage />
      </PropertyRoute>
      <PropertyRoute path={routes.settingsPage}>
        <PropertySettingsPage />
      </PropertyRoute>
      <PropertyRoute path={routes.inventoryPage} exact>
        <InventoryPage />
      </PropertyRoute>
      <PropertyRoute exact path={routes.productsPage}>
        <GiftCardPage />
      </PropertyRoute>

      <GiftCardStoreRoute path={routes.giftCardStoreTermsPage} className={styles.paddingWrapper} exact>
        <GiftCardStoreTermsPage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.giftCardStoreCheckoutPage} exact>
        <GiftCardStoreCheckoutPage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.giftCardStoreConfirmationPage}>
        <GiftCardStoreConfirmationPage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.giftCardStoreCartPage} exact>
        <GiftCardStoreCartPage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.useGiftCardStorePage} exact>
        <UseGiftCardStorePage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.cancelGiftCardStorePage} exact>
        <CancelGiftCardStorePage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.giftCardStoreBookingPage} exact>
        <GiftCardStoreBookingPage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.giftCardStoreDetailPage} exact>
        <GiftCardStoreDetailPage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.giftCardStorePaymentPage} exact>
        <GiftCardStorePaymentPage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.giftCardStoreBookingCheckoutPage} exact>
        <GiftCardStoreBookingCheckoutPage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.giftCardStoreBookingConfirmationPage} exact>
        <GiftCardStoreBookingConfirmationPage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.giftCardStorePage} className={styles.paddingWrapper} exact>
        <GiftCardStorePage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.giftCardPropertiesPage} exact>
        <GiftCardPropertiesListPage />
      </GiftCardStoreRoute>

      <GiftCardStoreRoute path={routes.oldGiftCardStoreCheckoutPage} exact>
        <GiftCardStoreCheckoutPage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.oldGiftCardStoreConfirmationPage}>
        <GiftCardStoreConfirmationPage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.oldGiftCardStoreCartPage} exact>
        <GiftCardStoreCartPage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.oldUseGiftCardStorePage} exact>
        <UseGiftCardStorePage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.oldGiftCardStoreBookingPage} exact>
        <GiftCardStoreBookingPage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.oldGiftCardStoreDetailPage} exact>
        <GiftCardStoreDetailPage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.oldGiftCardStorePaymentPage} exact>
        <GiftCardStorePaymentPage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.oldGiftCardStoreBookingCheckoutPage} exact>
        <GiftCardStoreBookingCheckoutPage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.oldGiftCardStoreBookingConfirmationPage} exact>
        <GiftCardStoreBookingConfirmationPage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.oldGiftCardStorePage} className={styles.paddingWrapper} exact>
        <GiftCardStorePage />
      </GiftCardStoreRoute>
      <GiftCardStoreRoute path={routes.oldGiftCardPropertiesPage} exact>
        <GiftCardPropertiesListPage />
      </GiftCardStoreRoute>

      <Route path="*">
        <Redirect to={routes.default} />
      </Route>
    </Switch>
  );
}
