import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

import Loading from 'components/loading';
import { IWebStoreProperty } from 'components/gift_card_buy_store_page_layout/gift_card_buy_store_page_layout.types';

import { PropertyGiftCardsDataContext } from 'containers/data_context';

import styles from './gift_card_store_terms_page.module.scss';

const GiftCardStoreTermsPage: React.FC = () => {
  const { t } = useTranslation();

  const {
    propertyInfo: { data: propertyInfo, isLoading },
  } = useContext(PropertyGiftCardsDataContext);

  const renderStoreTerms = (propertyInfo: IWebStoreProperty) => {
    return (
      <div data-testid="StoreTerms">
        <Typography.Title style={{ fontFamily: propertyInfo?.storeSetting?.titleFont }}>
          {t('general.terms_and_conditions')}
        </Typography.Title>
        <Typography style={{ fontFamily: propertyInfo?.storeSetting?.textFont }}>
          {propertyInfo?.storeSetting?.termsAndConditions?.split('\n')?.map((line: string, index: number) => (
            <React.Fragment key={index}>
              <Typography.Text style={{ fontFamily: propertyInfo?.storeSetting?.textFont }}>{line}</Typography.Text>
              <br />
            </React.Fragment>
          ))}
        </Typography>
      </div>
    );
  };

  return (
    <div className={styles.root} data-testid="GiftCardStoreTermsPage">
      {!propertyInfo || isLoading ? <Loading size={48} /> : renderStoreTerms(propertyInfo)}
    </div>
  );
};

export default GiftCardStoreTermsPage;
