// Generated with util/create-component.js
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Empty } from 'antd';
import classNames from 'classnames';

import Loading from 'components/loading';
import { IRatePlan, IPropertyUnit } from 'components/gift_card_store_booking/gift_card_store_booking.types';

import formatCurrencyPrice from 'utils/format_currency_price';
import GiftCardUnit from '../gift_card_unit/gift_card_unit';
import { IGiftCardUnitsProps } from './gift_card_units.types';
import styles from './gift_card_units.module.scss';

const GiftCardUnits: React.FC<IGiftCardUnitsProps> = ({
  units,
  isLoadingGiftCardUnitsData,
  onBook,
  numberOfNights,
  upsellRatePlans,
  property,
}) => {
  const { t } = useTranslation();

  const [selectedUnit, setUnitToBook] = useState<IPropertyUnit>();
  const [isSelectedUpsell, setIsSelectedUpsell] = useState<boolean>(false);
  const [normalUnits, setNormalUnits] = useState<IPropertyUnit[]>();
  const [normalAvailability, setNormalAvailability] = useState<boolean>(false);
  const [upsellUnits, setUpsellUnits] = useState<IPropertyUnit[]>();

  useEffect(() => {
    const normal: IPropertyUnit[] = [];
    const upsell: IPropertyUnit[] = [];

    if (upsellRatePlans instanceof Array) {
      units.forEach(unit => {
        unit.ratePlans.forEach(ratePlan => {
          const upsellRate = upsellRatePlans.find(rate => rate.ratePlanId === ratePlan.parentRatePlanId);
          if (upsellRate) {
            unit.upsellPrice = Number(upsellRate.price);
            upsell.push(unit);
          } else {
            normal.push(unit);
          }
        });
      });
    } else {
      units.forEach(unit => {
        normal.push(unit);
      });
    }

    setNormalAvailability(normal.some(unit => unit.ratePlans.length > 0));
    setNormalUnits(normal);
    setUpsellUnits(upsell.sort((a, b) => (a.upsellPrice ?? 1) - (b.upsellPrice ?? 1)));
  }, [units]);

  const handleSelectRatePlan = (propertyUnit: IPropertyUnit, ratePlan: IRatePlan, isUpsell: boolean) => {
    setUnitToBook(propertyUnit);
    setIsSelectedUpsell(isUpsell);
    onBook(propertyUnit, ratePlan, isUpsell);
  };

  const isSelectedUnit = useCallback(
    (propertyUnit: IPropertyUnit) => {
      return selectedUnit?.id === propertyUnit.id;
    },
    [selectedUnit],
  );

  if (isLoadingGiftCardUnitsData) {
    return (
      <div data-testid="GiftCardUnits" className={styles.loadingContainer}>
        <Loading />
      </div>
    );
  }

  if (!units) return null;

  return (
    <div data-testid="GiftCardUnits" className={styles.giftCardUnitSection}>
      <div className={classNames(styles.roomTypeOptionHeader, 'highlighted')}>{t('general.select_your_room_type')}</div>

      <div className={styles.roomTypeTable}>
        <Row
          className={classNames(styles.roomTypeHeaderColumn, 'highlighted')}
          align="middle"
          justify="center"
          gutter={16}
        >
          <Col sm={2} xs={0}></Col>
          <Col sm={10} xs={24}>
            {t('rates_table_hotel.unit_type')}
          </Col>
          <Col sm={4} xs={0}>
            {t('hotel_page.guests')}
          </Col>
          <Col sm={4} xs={0}>
            {t('bookings_list.nights_title')}
          </Col>
          <Col sm={4} xs={0} className={styles.lastColumnLabel}>
            {t('general.select')}
          </Col>
        </Row>

        {normalAvailability ? (
          normalUnits?.map((propertyUnit, index) => {
            if (propertyUnit.ratePlans.length > 0) {
              return (
                <div key={`room-type-${index}`} className={styles.roomTypeContainer}>
                  <GiftCardUnit
                    key={index}
                    propertyUnit={propertyUnit}
                    onBook={handleSelectRatePlan}
                    numberOfNights={numberOfNights}
                    isSelected={isSelectedUnit(propertyUnit)}
                    isUpsell={false}
                    property={property}
                  />
                </div>
              );
            }
          })
        ) : (
          <Row className={classNames(styles.noNormalAvailability)} align="middle" justify="center" gutter={16}>
            <Col className={classNames(styles.noNormalAvailabilityFont, 'highlighted')}>
              <Empty description={<span>{t('gift_card.only_upsells_available')}</span>} />
            </Col>
          </Row>
        )}

        <div className={styles.upsellSection}>
          {upsellUnits && upsellUnits.length > 0 && (
            <legend className={classNames(styles.upsellLegend, 'highlighted')}>{t('gift_card.also_available')}</legend>
          )}
          {upsellUnits && upsellUnits.length > 0 && (
            <Row
              className={classNames(styles.roomTypeHeaderColumn, 'highlighted')}
              align="middle"
              justify="center"
              gutter={16}
            >
              <Col sm={2} xs={0}></Col>
              <Col sm={7} xs={24}>
                {t('rates_table_hotel.unit_type')}
              </Col>
              <Col sm={3} xs={0}>
                {t('gift_card.price')}
              </Col>
              <Col sm={4} xs={0}>
                {t('hotel_page.guests')}
              </Col>
              <Col sm={4} xs={0}>
                {t('bookings_list.nights_title')}
              </Col>
              <Col sm={4} xs={0} className={styles.lastColumnLabel}>
                {t('general.select')}
              </Col>
            </Row>
          )}
          {upsellUnits?.map((propertyUnit, index) => {
            if (propertyUnit.ratePlans.length > 0) {
              return (
                <div
                  key={`room-type-${index + (normalUnits ? normalUnits?.length : 0)}`}
                  className={styles.roomTypeContainer}
                >
                  <GiftCardUnit
                    key={index}
                    propertyUnit={propertyUnit}
                    onBook={handleSelectRatePlan}
                    numberOfNights={numberOfNights}
                    isSelected={isSelectedUnit(propertyUnit)}
                    isUpsell={true}
                    property={property}
                  />
                </div>
              );
            }
          })}
        </div>
        {isSelectedUpsell && (
          <Row justify="space-between" className={classNames(styles.total, 'highlighted')}>
            <Col>{t('hotel_page.total')}</Col>
            <Col className={styles.totalPrice}>
              {formatCurrencyPrice(
                selectedUnit?.upsellPrice ? selectedUnit.upsellPrice : 0,
                property?.currency,
                property?.country,
              )}
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default GiftCardUnits;
