import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Space, TablePaginationConfig, Dropdown, MenuProps, notification } from 'antd';
import type { NotificationArgsProps } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Dayjs } from 'dayjs';

import { DownOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';

import SearchInput from 'components/search_input/search_input';
import GiftCardOrders from 'components/gift_card_orders/gift_card_orders';
import { IGiftCard } from 'components/gift_card_orders/gift_card_orders.types';
import { IOrdersFilters } from 'pages/gift_card_orders_page/gift_card_orders_page.types';
import {
  PropertyGiftCardsActionsContext,
  PropertyGiftCardsDataContext,
  PropertySettingsDataContext,
} from 'containers/data_context';

import styles from './gift_card_orders_page.module.scss';
import GiftCardOrdersFilters from 'components/gift_card_orders_filters/gift_card_orders_filters';
import { DATE_API_FORMAT } from 'constants/date_formats';
import { IProperty } from 'components/gift_card_list/created_gift_cards/created_gift_cards.types';

type NotificationPlacement = NotificationArgsProps['placement'];

const DEFAULT_PAGINATION = {
  page: 1,
  limit: 10,
};

const DEFAULT_ORDER = {
  orderBy: 'created_at',
  orderDirection: 'desc',
};

const DEFAULT_FILTERS: IOrdersFilters = {
  pagination: DEFAULT_PAGINATION,
  ...DEFAULT_ORDER,
};

const openNotification = (placement: NotificationPlacement, downloadStarted: string, downloadDescription: string) => {
  notification.info({
    message: downloadStarted,
    description: downloadDescription,
    placement,
  });
};

const GiftCardOrdersPage: React.FC = () => {
  const { t } = useTranslation();
  const { getGiftCardsByPropertyId, getGiftCardsExport } = useContext(PropertyGiftCardsActionsContext);
  const {
    selectedProperty,
    propertiesList: { data: properties },
  } = useContext(PropertySettingsDataContext);

  const [allFilters, setFilters] = useState(DEFAULT_FILTERS);

  const {
    giftCardsByProperty: { data, isLoading },
  } = useContext(PropertyGiftCardsDataContext);

  useEffect(() => {
    if (!selectedProperty) return;

    getGiftCardsByPropertyId({ ...allFilters, propertyId: selectedProperty });
  }, [selectedProperty, allFilters]);

  const property = properties?.find((p: IProperty) => p.value === selectedProperty);

  const handleSearch = async (term: string) => {
    setFilters({ ...allFilters, term: term, pagination: DEFAULT_PAGINATION });
  };

  const handleSelectBooked = async (booked: string) => {
    if (booked === 'used') {
      setFilters({ ...allFilters, booked: true, pagination: DEFAULT_PAGINATION });
    } else if (booked === 'unused') {
      setFilters({ ...allFilters, booked: false, pagination: DEFAULT_PAGINATION });
    } else if (booked === 'anyStatus') {
      const appliedFilters = JSON.parse(JSON.stringify(allFilters));
      delete appliedFilters['booked'];
      setFilters(appliedFilters);
    }
  };

  const handleSelectKind = async (kind: string) => {
    if (kind === 'giftCard') {
      setFilters({ ...allFilters, kind: 'gift_card', pagination: DEFAULT_PAGINATION });
    } else if (kind === 'upsell') {
      setFilters({ ...allFilters, kind: 'upsell', pagination: DEFAULT_PAGINATION });
    } else if (kind === 'anyKind') {
      const appliedFilters = JSON.parse(JSON.stringify(allFilters));
      delete appliedFilters['kind'];
      setFilters(appliedFilters);
    }
  };

  const handleSelectDeliveryStatus = async (deliveryStatus: string) => {
    if (deliveryStatus === 'sent') {
      setFilters({ ...allFilters, delivered: true, pagination: DEFAULT_PAGINATION });
    } else if (deliveryStatus === 'notSent') {
      setFilters({ ...allFilters, delivered: false, pagination: DEFAULT_PAGINATION });
    } else if (deliveryStatus === 'anySent') {
      const appliedFilters = JSON.parse(JSON.stringify(allFilters));
      delete appliedFilters['delivered'];
      setFilters(appliedFilters);
    }
  };

  const handleSelectDeliveryMethod = async (deliveryMethod: string) => {
    if (deliveryMethod === 'email') {
      setFilters({ ...allFilters, sendByLetter: false, pagination: DEFAULT_PAGINATION });
    } else if (deliveryMethod === 'letterMail') {
      setFilters({ ...allFilters, sendByLetter: true, pagination: DEFAULT_PAGINATION });
    } else if (deliveryMethod === 'anyMail') {
      const appliedFilters = JSON.parse(JSON.stringify(allFilters));
      delete appliedFilters['sendByLetter'];
      setFilters(appliedFilters);
    }
  };

  const handleChange = useCallback(
    (
      currentPagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<IGiftCard> | SorterResult<IGiftCard>[],
    ) => {
      let appliedFilters = JSON.parse(JSON.stringify(allFilters));
      Object.keys(filters).map((filterName: string) => {
        if (!filters[filterName]) {
          delete appliedFilters[filterName];
        } else {
          appliedFilters[filterName] = filters[filterName]?.[0];
        }
      });

      if (!(sorter as SorterResult<IGiftCard>)?.order) {
        appliedFilters = { ...appliedFilters, ...DEFAULT_ORDER };
      } else {
        appliedFilters.orderBy = String((sorter as SorterResult<IGiftCard>)?.field)
          .replace(/[A-Z]/g, '_' + '$&')
          .toLowerCase();
        appliedFilters.orderDirection = (sorter as SorterResult<IGiftCard>).order === 'ascend' ? 'asc' : 'desc';
      }

      const { current, pageSize } = currentPagination;

      appliedFilters.pagination = {
        page: allFilters.pagination?.page !== current ? current : 1,
        limit: pageSize || 30,
      };

      setFilters(appliedFilters);
    },
    [allFilters],
  );

  const paginationData = {
    current: allFilters.pagination?.page,
    total: data?.meta?.total,
    pageSize: allFilters.pagination?.limit,
    hideOnSinglePage: true,
    showSizeChanger: false,
  };

  const renderMenu = (): MenuProps => {
    const menuItems: MenuProps['items'] = [
      {
        label: t('gift_card.download_csv'),
        key: 'download_csv',
        onClick: () => downloadReport('csv'),
        disabled: false,
      },
      { type: 'divider' },
      {
        label: t('gift_card.download_xlsx'),
        key: 'download_xlsx',
        onClick: () => downloadReport('xlsx'),
        disabled: false,
      },
    ];

    return { items: menuItems };
  };

  const [open, setOpen] = useState(false);

  const handleDatePickerChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates === null) {
      const appliedFilters = JSON.parse(JSON.stringify(allFilters));
      delete appliedFilters['soldFrom'];
      delete appliedFilters['soldTo'];
      setFilters(appliedFilters);
    } else if (dates[0] && dates[1]) {
      const soldFrom = dates[0].format(DATE_API_FORMAT);
      const soldTo = dates[1].format(DATE_API_FORMAT);
      setFilters({ ...allFilters, soldFrom: soldFrom, soldTo: soldTo, pagination: DEFAULT_PAGINATION });
    }

    setOpen(false);
  };

  const downloadReport = async (kind: string) => {
    try {
      openNotification('bottomRight', t('gift_card.download_started'), t('gift_card.download_description'));
      const downloadedExport = await getGiftCardsExport({
        ...allFilters,
        exportFormat: kind,
        propertyId: selectedProperty,
      });
      console.log(downloadedExport);
      saveAs(downloadedExport, `${kind}_export`);
    } catch (e) {
      console.log('error', e);
    }
  };

  return (
    <div className={styles.root} data-testid="GiftCardOrdersPage">
      <div className={styles.giftCardOrdersList}>
        <div className={styles.flexContainer}>
          <SearchInput
            onSearch={handleSearch}
            placeholder={t('gift_card.search_orders')}
            className={styles.searchInput}
            variant="borderless"
          />
          <Space className={styles.spaceStyle}>
            <GiftCardOrdersFilters
              onSelectKind={handleSelectKind}
              onSelectBooked={handleSelectBooked}
              onSelectDeliveryStatus={handleSelectDeliveryStatus}
              onSelectDeliveryMethod={handleSelectDeliveryMethod}
              onDatePickerChange={handleDatePickerChange}
              setOpenDatePicker={setOpen}
              openDatePicker={open}
            />
            <Dropdown menu={renderMenu()}>
              <div className={styles.actionsLink}>
                <CloudDownloadOutlined className={styles.downloadIcon} />
                <span className={styles.actionLinkText}>{t('gift_card.orders_export')}</span> <DownOutlined />
              </div>
            </Dropdown>
          </Space>
        </div>

        <GiftCardOrders
          giftCardsList={data?.data}
          onChange={handleChange}
          pagination={paginationData}
          isLoading={isLoading}
          property={property}
        />
      </div>
    </div>
  );
};

export default GiftCardOrdersPage;
